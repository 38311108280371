import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";

const Placeholder = ({ children }) => {
  return <div style={{ color: "#AFB9C3" }}>{children}</div>;
};

export default function ApprovalSelectTabOptions({
  data,
  value,
  setValue,
  defaultValue,
  placeholder,
  valueMultiple,
  setValueMultiple,
  onChangeHandler,
  inputDisable,
  Multiple = false,
  i,
}) {
  console.log(value);
  console.log(valueMultiple);
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleChangeMultiple = (onChangeValue, i) => {
    const inputdata = [...valueMultiple];
    inputdata[i][onChangeValue.target.name] = onChangeValue.target.value;
    setValueMultiple(inputdata);
    console.log(valueMultiple);
  };

  const handleDelete = (i) => {
    const deleteValue = [...valueMultiple];
    deleteValue.splice(i, 1);
    setValue(deleteValue);
  };
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
        width: 250,
        background: "#EBEEF0",
      },
    },
  };



  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={value}
      defaultValue={defaultValue}
      label="orignator"
      displayEmpty
      disabled={inputDisable}
      onChange={onChangeHandler}
      // IconComponent={null}
      // endAdornment={<IconButton onClick={() => handleDelete(i)}><DeleteIcon/></IconButton>}
      // endAdornment={valueMultiple[i] ==="" ? null : <IconButton onClick={() => handleDelete(i)}><DeleteIcon/></IconButton>}
      MenuProps={MenuProps}
      renderValue={
        value !== ""
          ? undefined
          : () => <Placeholder>{placeholder}</Placeholder>
      }
    >
      {data.map((originator, index) => {
        return (
          <MenuItem key={index} value={originator.id || originator._id}>
            {originator.name || originator.title}
          </MenuItem>
        );
      })}
    </Select>
  );
}


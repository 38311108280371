import Delete from "../../assets/icons/blueDelete.svg";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { ChooseFileComponent, SelectedFileComponent } from "./UploadComponents";
import { useState } from "react";
import styles from "./Wrapper.module.css";
import {
  uploadKYCDoc,
  deleteDoc,
} from "../../redux/originator/originatorSlice";
import { uploadDocs } from "../../redux/L1/editOriginatorSlice";
import { uploadValidationFile } from "../../redux/L1/secondaryCheckSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { showErrorAlertAction } from "../../redux/app/appSlice";
import {
  uploadLoanPool,
  uploadLoanTemplate,
  removeUploadLoanTemplate,
} from "../../redux/originator/poolUploadSlice";

const UploadDPD = ({
  setLoanFile,
  setdata,
  docName,
  isdisabled = false,
  loanId,
  dealId,
}) => {
  const [myFiles, setMyFiles] = useState([]);
  const [state, setState] = useState(null);

  let dispatch = useDispatch();
  let poolState = useSelector((state) => state.poolUpload);

  console.log({ poolState });
  

  const onDrop = useCallback((acceptedFiles) => {
    let formData = new FormData();
    // setdata(true);
    formData.set("file", acceptedFiles[0]);
    if (acceptedFiles[0] == undefined) {
      dispatch(showErrorAlertAction("Please select a supported format file"));
      return;
    }
    dispatch(
      uploadLoanTemplate({
        file: formData.getAll("file")[0],
        fileName: formData.getAll("file")[0].name,
      })
    );
    setLoanFile(formData.getAll("file")[0]);
    console.log("hey");
    console.log(acceptedFiles[0]);
    setState(acceptedFiles[0]);
  }, []);

  const removeFile = () => {
    setState(null);
    setLoanFile(null)
    dispatch(removeUploadLoanTemplate(docName));
  };

  const { getRootProps, getInputProps, acceptedFiles, inputRef } = useDropzone({
    multiple: false,
    onDrop,
    accept: {
      xlsx: [".xlsx"],
    },
  });

  //? useEffect
  useEffect(() => {
    if (poolState[docName]) {
      setState(poolState[docName]);
      setdata(false);
    }
  }, [poolState[docName]]);

  const Test = () => {
    return (
      <>
        <input
          {...getInputProps()}
          disabled={isdisabled === true ? true : false}
        />
        {poolState.fileName == null ? (
          <div className={styles.wrapper}>
            <div className={styles.DetailsWrapper}>
              <div className={styles.together}>
                <p>Loading...</p>
              </div>
            </div>
          </div>
        ) : (
          <SelectedFileComponent
            fileName={poolState.fileName}
            onClickHandler={removeFile}
          />
        )}
      </>
    );
  };

  const FileComponent =
    state && state != null ? <Test /> : console.log("state is null");

  console.log({ state });

  return (
    <div>
      {state && state != null ? (
        FileComponent
      ) : (
        <div {...getRootProps()} style={{ cursor: "pointer" }}>
          <ChooseFileComponent label =  "(Supported file formats : .xlsx)" />
        </div>
      )}
    </div>
  );
};

export default UploadDPD;

const Paths = {
  HOME: "/home",
  DASHBOARD: "/dashboard",
  // LOGIN: "/login",
  LOGIN: "/",
  ORIGINATOR: "/originator",
  CREATE_DEAL: "/create",
  ORIGINATOR_LIST: "/originator/list",
  CREATE_ORIGINATOR: "/create/originator",
  ORIGINATOR_FORM: "/create/originator/profile",
  DEAL_FORM: "/create/deals/createdeal",
  DEALS_LIST: "/list/deals",
  VIEW_DETAILS: "/view/:dealId/details",
  ORIGINATOR_DETAILS: "/create/originator/details",

  EDITORIGINATOR: "/editoriginator",
  VIEW_EDITORIGINATOR: "/editoriginator/view/details/:originatorId",

  CONDUCTPOOLSHORTLISTING: "/pool-shortlisting/conduct",
  POOLSHORTLISTINGDEALIST: "/pool-shortlisting/conduct/poolshortlistingdealist",
  POOLSHORTLISTING:
    "/pool-shortlisting/conduct/poolshortlistingdealist/:dealId/details",

  CREDITBUREAUREPORTDEALIST: "/creditbureaureport/dealist",
  KYCDOCUMENTSREQUEST: "/deal/:dealId/details/:uniqueCode/requestkycdocuments",

  DEALDUPLICATIONDEALIST: "/dealduplication/deal/list",
  DEALDUPLICATIONDETAILS: "/dealduplication/details",
  DEALDUPLICATION: "/dealduplication",

  //L2 Routes
  L2DEALLIST: "/l2deallist",
  L2VIEWDETAILS: "/l2deallist/:dealId/l2viewdetails",
  L2OIRIGINATORSLIST: "/l2originatorlist",
  L2OIRIGINATORSDETAILS: "/view/:originatorId/l2originatorsdetails",
  ORIGINATORHOME: "/originatorhome",
  ORIGINATORCOMPLETEKYC: "/originatorcompletekyc",
  ORIGINATORDEALLIST: "/originatordealist",
  PRIMARYCHECKDEALLIST: "/primary-check",
  L2LOANDEALIST: "/l2loan/list",
  L2LOANDETAILS:
    "/loan/pool-upload/:poolUploadId/deal-details/:dealId/loan-details",
  L2CREDITFILTERSDEALIST: "/l2creditfiltersdealist",
  L2CREDITFILTERSDETAILS: "/:dealId/l2creditfiltersdetails",

  //Phase 2 Routes
  PHASE1HOME: "/phase1dashboard",
  PHASE2UPLOADNEWREPORT: "/phase2uploadnewreport",
  PHASE2DEALDETAILS: "/phase2dealdetails",
  MONTHLYCOLLECTIONREPORT: "/phase2monthlycollectionreport",
  MONTHLYCOLLECTIONREPORTSUMMARY: "/phase2monthlycollectionreportsummary",
  LISTOFREPORTS: "/phase2listofReports",
  
  // REPORTS: "/phase2listofReports/:id/:dealname",
  REPORTS: "/phase2listofReports/:id",
  GENERATEREPORT: "/phase2generatereports",
  NOTIFICATIONS: "/notifications",
  ORIGINATORUPLOADPOOL: "/originator/:dealId/upload-pool",
  ORIGINATORLOANUPDSUMMARY: "/originator/deal/:dealId/loan/summary",

  ORIGINATORCREDITDEALSLIST: "/originatorcreditdealist",
  ORIGINATORCREDITUPLOAD: "/:dealId/originatorcreditupload",

  ORIGINATORKYCLINKDEALLIST: "/originatorkyclinkdealist",
  ORIGINATORKYCLINKUPDATE: "/:dealId/originatorkyclinkupdate",
  ORIGINATORPROFILELIST: "/originatorprofilelist",
  ORIGINATORPROFILETABLE: "/originatorprofilelist/list/:dealId/profile",

  AUDITORDEALIST: "/auditordealist",
  AUDITORKYCAPPROVEREJECT: "/auditor/:dealId/kycapprovereject",
  AUDITORLOANUPDSUMMARY: "/auditor/:dealId/loan/summary",

  APPROVERDEALIST: "/approverdealist",
  APPROVERVIEWDEALDETAILS: "/approver/:dealId/deal-details",
  APPROVERDOCUMENTUPDDEALIST: "/approverdocumentupddealist",
  APPROVERUPDDOC: "/approverupddocs",

  VIEW_VOUCHER: "/view/voucher-details",
  VIEW_SOA_REPORT: "/view/soa-report-details",

  PORTFOLIOFINALDEALLIST: "/portfolio/deal-list",
  PORTFOLIOVIEWDETAILS: "/portfolio/view/:dealId/details",
  PORTFOLIOFINALVIEWDEALDETAILS: "/portfolio/:dealId/deal-details",
  PORTFOLIOFINALLOANDETAILS: "/portfolio/loan-deatils",

  EntityDealList: "/entitydeallist",
  CreateEntity: "/createntity/:dealId",
  EntityDealDeatils: "/entitydealview/:dealId/details",
  EntityLoanDeatils: "/entityloandeatils/:dealId/details",

  TERM_SHEET: "/termsheet",
  DAILY_DPD : "/daily-dpd",

  DOWNLOAD_REPORT: "/download-report/:fileId/:reportName",
  MONTH_END_UPLOADS: "/month-end/uploads",
  MONTH_END_DEALS:"/month-end/deallist",
  MONTH_END_UPLOAD_VIEW: "/month-end/deal/:dealId/year/:year/uploads",
  UNAUTHORIZED: "/unauthorized",

  F1LISTOFDEAL: "/f1/deal",
  F1DEALDETAILS: "/f1/deal/:dealId/details",

  SWAYAMUSERDEALLIST: "/swayamUserdeallist",
  SWAYAMUSERDEALDETAILS: "/swayamUserdeallist/:dealId/details"
};

export default Paths;

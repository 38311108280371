import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { isAuthenticated } from "../../helper/AuthHelper";


const initialState = {
    checkedPages: [
      { pageName: "InvestmentMemo", isChecked: false },
      { pageName: "ApprovalCommitteeSetup", isChecked: false }
    ],
    approverData: [],
    loadind: null,
    error: null,
  };


  export const getApprovers = createAsyncThunk(
    "getApprovers",
    async ({ approverType, dealId}, { rejectWithValue }) => {
      try {
        const response = await instance.get(
          `/portfolio-service/originator/getApprovers?approverType=${approverType}&dealId=${dealId}`
        );
        console.log({ response });
        return response.data;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  );

  export const goForUpdateDeal = createAsyncThunk(
    "goForUpdateDeal",
    async (values, thunkApi) => {
      let data = JSON.stringify({
        dealId: values.dealId,
        approvers: values.approvers,
        interestRateType: values.interestRateType,
        ROI: values.ROI,
        XIRR: values.XIRR,
        maxLossEstimation: values.maxLossEstimation,
        minLossEstimation: values.minLossEstimation,
        purchaseConsiderationAmount: values.purchaseConsiderationAmount,
        processingFees: values.processingFees,
        processingFeesAmount: values.processingFeesAmount
      });
      try {
        const dealData = await instance.post(
          "/portfolio-service/deal/updateDeal",
  
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + isAuthenticated("Token"),
            },
          }
        );
        return {
          ...dealData,
        };
      } catch (err) {
        return thunkApi.rejectWithValue(err);
      }
    }
  );


  const finalDealRequestSlice = createSlice({
    name: "finalDealRequestSlice",
    initialState,
    reducers: {
        pageStatusAction: (state, { payload }) => {
          console.log(payload);
          state.checkedPages = state.checkedPages.map((data) => {
            let { pageName, isChecked } = data;
    
            return payload.pageName === pageName
              ? { ...data, isChecked: true }
              : data;
          });
        },
        extraReducer: (builder) => {
            builder.addCase(getApprovers.pending, (state) => {
                state.loading = true;
              });
              builder.addCase(getApprovers.fulfilled, (state, { payload }) => {
                console.log({ payload });
                state.loading = false;
                state.approverData = payload;
              });
              builder.addCase(getApprovers.rejected, (state, { payload }) => {
                console.log({ payload });
                state.loading = false;
                state.error = "Something went wrong";
              });

              builder.addCase(
                goForUpdateDeal.rejected,
                (state, { payload }) => {
                  console.log({ payload });
                  console.log(payload.response.data.message);
                  state.loading = false;
                  state.error = payload.response.data.message;
                }
              );
        }
    }
  })

export const { pageStatusAction } = finalDealRequestSlice.actions;

export const finalDealRequestReducer = finalDealRequestSlice.reducer;
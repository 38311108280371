import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import { Input, FormControl } from "@mui/material";
export const SuffixTextField = ({
  placeholder,
  endAdornmentText,
  onChangeHandler,
  value,
  type = "number"
}) => {
  return (
    <FormControl variant="standard">
      <Input
        // onInput={(e) => {
        //   e.target.value = Math.max(0, parseInt(e.target.value))
        //     .toString()
        //     .slice(0, 3);
        // }}
        min={0}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onChangeHandler}
        endAdornment={
          <InputAdornment position="end">{endAdornmentText}</InputAdornment>
        }
      />
    </FormControl>
  );
};
export const SuffixTextFieldE = ({
  placeholder,
  endAdornmentText,
  onChangeHandler,
  value,
  inputDisable = false,
}) => {
  return (
    <FormControl variant="standard">
      <Input
        // onInput={(e) => {
        //   e.target.value = Math.max(0, parseInt(e.target.value))
        //     .toString()
        //     .slice(0, 3);
        // }}
        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
        min={0}
        type="number"
        value={value}
        disabled={inputDisable}
        placeholder={placeholder}
        onChange={onChangeHandler}
        endAdornment={
          <InputAdornment position="end">{endAdornmentText}</InputAdornment>
        }
      />
    </FormControl>
  );
};
export const SuffixTextFieldV12 = ({
  placeholder,
  endAdornmentText,
  onChangeHandler,
  value,
}) => {
  return (
    <FormControl variant="standard">
      <Input
        // onInput={(e) => {
        //   e.target.value = Math.max(0, parseInt(e.target.value))
        //     .toString()
        //     .slice(0, 18);
        // }}
        onInput={(e) => {
          let value = e.target.value;
          e.target.value = value.toString();
        }}
        min={0}
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={onChangeHandler}
        endAdornment={
          <InputAdornment position="end">{endAdornmentText}</InputAdornment>
        }
      />
    </FormControl>
  );
};

export const SuffixPrefixTextField = ({
  placeholder,
  endAdornmentText,
  startAdornmentText,
  onChangeHandler,
  value,
  isDecimal = false,
}) => {
  return (
    <FormControl variant="standard">
      <Input
        onInput={(e) => {
          if (isDecimal === false) {
            e.target.value = Math.max(0, parseInt(e.target.value))
              .toString()
              .slice(0, 2);
          }

        }}
        min={0}
        value={value}
        type="number"
        placeholder={placeholder}
        onChange={onChangeHandler}
        endAdornment={
          <InputAdornment position="end">{endAdornmentText}</InputAdornment>
        }
        startAdornment={
          <InputAdornment position="start">{startAdornmentText}</InputAdornment>
        }
      />
    </FormControl>
  );
};



import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../utils/Loader";
import TabFooter from "./../TabFooter/TabFooter";
import DescriptionIcon from "@mui/icons-material/Description";
import { DocumentHeader } from "../../DocumentHeaderWrapper/DocumentHeader";
import { pageStatusAction, uploadDPD } from "../../../redux/DailyDPD/DailyDPDSlice";
import { showAlertAction } from "../../../redux/app/appSlice";
import UploadDPD from "../../Wrapper/UploadDPD";
import { getTemplate } from "../../../redux/TermSheet/TermSheetSlice";
import ViewDownloadDeleteComp from "../../L2/Phase2DownloadComp";

const UploadDPDDocuments = ({ nextTabHandler }) => {
  let dispatch = useDispatch();
  let loading = useSelector((state) => state.deal.loading);

  const [loanFile, setLoanFile] = useState(null);
  const [templateData, setTemplateData] = useState("");
  const [newloader, setNewLoader] = useState(true);
  const [state, setState] = useState(null);
  const [status, setStatus] = useState(true);
  const dealId = localStorage.getItem("dealId");

  useEffect(() => {
    dispatch(getTemplate({ type: "DAILY_DPD" })).then((res) => {
      setTemplateData(res?.payload?.data);
      setNewLoader(false);
    });
  }, [dealId])

  const nextBtnClickHandler = (e) => {
    e.preventDefault();
    dispatch(
      uploadDPD({
        file: loanFile,
        dealId: dealId
      })
    ).then((res) => {
      if (res?.payload?.status == 200) {
        dispatch(showAlertAction(res?.payload?.data?.message));
      }
    })
    dispatch(pageStatusAction({ pageName: "uploadDocuments" }));
    nextTabHandler();
  };

  // button disable handler
  useEffect(() => {
    if (loanFile != undefined) {
      setStatus(false);
    } else {
      setStatus(true);
    }
  }, [loanFile]);
  console.log({ templateData });


  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>

          <DocumentHeader
            title={"DPD Template"}
            startIcon={
              <DescriptionIcon
                sx={{ color: "#5E7387", width: "20px", height: "20px" }}
              ></DescriptionIcon>
            }
          ></DocumentHeader>
          <div style={{ padding: "10px", width: "auto" }}>
            <ViewDownloadDeleteComp
              fileName={templateData?.file?.filename}
              id={templateData?.file?._id}
            ></ViewDownloadDeleteComp>
          </div>

          <DocumentHeader
            title={"Dcouments Uploads"}
            startIcon={
              <DescriptionIcon
                sx={{ color: "#5E7387", width: "20px", height: "20px" }}
              />
            }>
          </DocumentHeader>
          <div style={{ padding: "10px", width: "auto" }}>
            <UploadDPD
              docName="dpdDcouments"
              setLoanFile={setLoanFile}
              setdata={setState}
              isdisabled={false}
            />
          </div>
          <TabFooter
            onclickHandler={nextBtnClickHandler}
            isBtnDisable={status}
          />
        </>
      )}
    </>
  );
};

export default UploadDPDDocuments;

import DescriptionIcon from "@mui/icons-material/Description";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSingleDealDetails } from "../../../redux/Deals/dealSlice";
import Loader from "../../../utils/Loader";
import { DocumentHeader } from "../../DocumentHeaderWrapper/DocumentHeader";
import LabelWithDownload from "../../LabelWithDownload";
import { getDPDDocuments } from "../../../redux/DailyDPD/DailyDPDSlice";
import { Divider, Grid, Typography } from "@mui/material";
import TextWrapperComponent from "../../FormComponents/TextWrapperComponent";

const SummaryTab = ({ nextTabHandler }) => {
  let dispatch = useDispatch();
  const [dpdSummaryData, setDPDSummarytData] = useState("");
  const [newloader, setNewLoader] = useState(true);
  const [formatDate, setFormatDate] = useState("");
  const [formatTime, setFormatTime] = useState("");
  const dealState = useSelector((state) => state.deal);

  let dealid = localStorage.getItem("dealId");

  useEffect(() => {
    if (dealState?.filterdata) {
      let finalId = dealState?.filterdata?._id;
      setNewLoader(false);
      dispatch(getDPDDocuments({ dealId: finalId })).then((res) => {
        setDPDSummarytData(res?.payload?.data);
        const uploadDate = res?.payload?.data?.uploadedFile?.uploadDate;
        const date = new Date(uploadDate);
        const formatDate = date.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "long",
          year: "numeric",
        });
        const formatTime = date.toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
        });
        setFormatDate(formatDate);
        setFormatTime(formatTime);
      });
    }
  }, [dealState?.filterdata]);

  const summary = [
    {
      title: "Total Records",
      count: dpdSummaryData?.totalRecords,
    },
    {
      title: "Total Success Records",
      count: dpdSummaryData?.successRecords,
    },
    {
      title: "Total Rejected Records",
      count: dpdSummaryData?.rejectedRecords,
    },
  ];

  useEffect(() => {
    dispatch(getSingleDealDetails(dealid));
  }, []);

  return (
    <>
      {newloader ? (
        <Loader />
      ) : (
        <>
          <DocumentHeader title={"DPD Summary"}></DocumentHeader>
          <div style={{ padding: "20px" }}>
            <Grid container spacing={4}>
              {summary.map((item) => {
                return (
                  <Grid item xs={4} key={item.title}>
                    <TextWrapperComponent text={item.title}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: "#20303F",
                          fontWeight: 400,
                        }}
                      >
                        {item.count}
                      </Typography>
                    </TextWrapperComponent>
                  </Grid>
                );
              })}
            </Grid>
          </div>
          <Divider></Divider>

          {/* Render Success File if available */}
          {dpdSummaryData?.successFile && dpdSummaryData?.successFile?._id && (
            <>
              <DocumentHeader
                title={"Success File"}
                startIcon={
                  <DescriptionIcon
                    sx={{ color: "#5E7387", width: "20px", height: "20px" }}
                  />
                }
              ></DocumentHeader>
              <div
                style={{
                  padding: "24px",
                }}
              >
                <LabelWithDownload
                  // label="Success File "
                  fileId={dpdSummaryData?.successFile?._id || ""}
                  fileName={dpdSummaryData?.successFile?.filename || ""}
                />
              </div>
            </>
          )}

          {/* Render Rejected File if available */}
          {dpdSummaryData?.rejectedFile &&
            dpdSummaryData?.rejectedFile?._id && (
              <>
                <DocumentHeader
                  title={"Rejected File"}
                  startIcon={
                    <DescriptionIcon
                      sx={{ color: "#5E7387", width: "20px", height: "20px" }}
                    />
                  }
                ></DocumentHeader>
                <div
                  style={{
                    padding: "24px",
                  }}
                >
                  <LabelWithDownload
                    // label="Rejected File"
                    fileId={dpdSummaryData?.rejectedFile?._id || ""}
                    fileName={dpdSummaryData?.rejectedFile?.filename || ""}
                  />
                </div>
              </>
            )}

          {/* Render uploaded File if available */}
          {dpdSummaryData?.uploadedFile &&
            dpdSummaryData?.uploadedFile?._id &&
            formatDate &&
            formatTime && (
              <>
                <DocumentHeader
                  title={`Uploaded At ${formatDate}, ${formatTime}`}
                  startIcon={
                    <DescriptionIcon
                      sx={{ color: "#5E7387", width: "20px", height: "20px" }}
                    />
                  }
                ></DocumentHeader>
                <div
                  style={{
                    padding: "24px",
                  }}
                >
                  <LabelWithDownload
                    // label="uploaded File "
                    fileId={dpdSummaryData?.uploadedFile?._id || ""}
                    fileName={dpdSummaryData?.uploadedFile?.filename || ""}
                  />
                </div>
              </>
            )}
        </>
      )}
    </>
  );
};

export default SummaryTab;

import { combineReducers } from "redux";
import { appReducer } from "./app/appSlice";
import { kycApproveRejectReducer } from "./Auditor/kycApproveRejectSlice";
import { dealReducer } from "./Deals/dealSlice";
import { loanReducer } from "./Loans/loanSlice";
import { creditBureauReducer } from "./originator/creditBureauSlice";
import { originatorReducer } from "./originator/originatorSlice";
import { dashBoardReducer } from "./Phase2/DashBoardSlice";
import { histroyReducer } from "./Phase2/HistroySlice";
import { notificationsReducer } from "./Phase2/NotificationsSlice";
import { searchReportReducer } from "./Phase2/SearchReports";
import { uploadReportReducer } from "./Phase2/UploadReportSlice";
import { querySliceReducer } from "./Queries/queriesSlice";
import { poolUploadReducer } from "./originator/poolUploadSlice";
import { SecondaryCheckReducer } from "./L1/secondaryCheckSlice";
import { editOriginatorReducer } from "./L1/editOriginatorSlice";
import { kycDocumentsRequestReducer } from "./L1/kycDocumentsRequestSlice";
import { dealDuplicationReducer } from "./DealDuplication/dealDuplicationSlice";
import { termSheetRequestReducer } from "./TermSheet/TermSheetSlice";
import { entitySliceReducer } from "./Entity/entitySlice";
import { finalDealRequestReducer } from "./L1/finalDealSlice";
import { kycAMLRequestReducer } from "./Phase3/KYCAML/KYCAMLSlice";
import { dailyDPDRequestReducer } from "./DailyDPD/DailyDPDSlice";

export const combinedReducer = combineReducers({
  app: appReducer,
  originator: originatorReducer,
  deal: dealReducer,
  loan: loanReducer,
  uploadReport: uploadReportReducer,
  queries: querySliceReducer,
  dashboard: dashBoardReducer,
  notification: notificationsReducer,
  history: histroyReducer,
  creditScrub: creditBureauReducer,
  poolUpload: poolUploadReducer,
  auditorKYCApproveReject: kycApproveRejectReducer,
  secondaryCheck: SecondaryCheckReducer,
  editOriginator: editOriginatorReducer,
  kycDocumentsRequest: kycDocumentsRequestReducer,
  dealDuplication: dealDuplicationReducer,
  serachReport: searchReportReducer,
  termSheetRequest: termSheetRequestReducer,
  dailyDPDRequest: dailyDPDRequestReducer,
  entity: entitySliceReducer,
  finalDealRequest: finalDealRequestReducer,
  kycAMLRequest: kycAMLRequestReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "appSlice/logout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};
export default rootReducer;

import DescriptionIcon from "@mui/icons-material/Description";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Image from "../../assets/images/Group 42722.svg";
import Layout from "../../components/Layout/Layout";
import TabsLayout from "../../components/Layout/TabsLayout/Layout";
import Tabs from "../../components/Tabs/Tabs";
import Loader from "../../utils/Loader";
import Paths from "../../components/constants/Path";
import { setStatus } from "../../redux/Loans/loanSlice";
import UploadDPDDocuments from "../../components/Tabs/DailyDPDTabs/UploadDPDDocuments";
import { getDPDDocuments } from "../../redux/DailyDPD/DailyDPDSlice";
import SummaryTab from "../../components/Tabs/DailyDPDTabs/SummaryTab";
import DealSelection from "../../components/Tabs/DailyDPDTabs/DealSelection";
import HistoryTab from "../../components/Tabs/DailyDPDTabs/HistoryTab";

const DailyDPDRequest = () => {
  const dispatch = useDispatch();
  let dailyDPDRequestState = useSelector((state) => state.dailyDPDRequest);
  let dealState = useSelector((state) => state.deal);
  let loading = useSelector((state) => state?.deal?.isTempLoading);
  let seletedDealId = localStorage.getItem("dealId");
  const [dealName, setDealName] = useState("")
  const [isSummary, setIsSummary] = useState(false)
  
  // for selected deal name
  useEffect(() => {
    if (seletedDealId && dealState?.allDeals) {
      const selectedDeal = dealState.allDeals.find((item) => item._id === seletedDealId);
      
      // Set deal name only if it's found and differs from the current value
      if (selectedDeal && selectedDeal.name !== dealName) {
        setDealName(selectedDeal.name);
      }
    }
  }, [seletedDealId, dealState?.allDeals, dealName]);

  const breadCrumbs = [
    {
      path: Paths.DASHBOARD,
      pathName: "Home",
    },
    {
      pathName: "Daily DPD",
    },
  ];
  
  useEffect(() => {
    if(seletedDealId){
      dispatch(getDPDDocuments({ dealId: seletedDealId })).then(
        (res) => {
          console.log({ res });
          if(res?.payload?.data){
            setIsSummary(true);
          }else{
            setIsSummary(false)
          }
        }
      );
    }
    
  },[seletedDealId])
 
  let tabsData = [
    {
      tabName: "Select Deal",
      comp: DealSelection,
      heading: `Select Deal for DPD`,
      showEditButton: false,
    },
    {
      tabName: "Upload Documents",
      comp: UploadDPDDocuments,
      heading: `Upload Documents for Deal`,
      showEditButton: false,
    },
  ];

  useEffect(() => {

  },[isSummary])
  console.log({isSummary});
  
  if(isSummary == true){
    tabsData.push(
      {
        tabName: "Summary",
        comp: SummaryTab,
        heading: `Summary for - ${dealName}`,
        showEditButton: false,
    },
    {
        tabName: "History",
        comp: HistoryTab,
        heading: `History for - ${dealName}`,
        showEditButton: false,
    },
  )
  }

  let TabsList = [
    {
      name: "DPD",
      component: (
        <Tabs
          tabs={tabsData}
          status={dailyDPDRequestState["checkedPages"]}
          breadCrumbs={breadCrumbs}
        />
      ),
      index: 0,
    },
  ];

  useEffect(() => {
    return () => {
      dispatch(setStatus(0));
    };
  }, []);

  let icon = (
    <DescriptionIcon
      sx={{
        color: "#2C4584",
      }}
    />
  );

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Layout breadCrumbs={breadCrumbs}>
          <TabsLayout
            TabsList={TabsList}
            formName={dealName}
            icon={icon}
            iconColor={"#DBE3F8"}
            image={Image}
          />
        </Layout>
      )}
    </>
  );
};

export default DailyDPDRequest;

import {
  BrowserRouter,
  Route,
  Routes,
  Switch,
  useHistory,
} from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import DemoHome from "./DemoHome";
import NotFound from "./FileNotFound";
import ListOfOriginators from "../Pages/Originator/ListOfOriginator";
import CreateDeal from "../Pages/Deal/CreateDeal";
import ViewDealDetails from "../Pages/Deal/ViewDealDetails";
import Login from "../Pages/login";
import OriginatorForm from "../Pages/Originator/OriginatorMultiForm";
import ListOfDeal from "../Pages/Deal/ListOfDeals";
import CreateOriginator from "../Pages/Originator/CreateOriginator";
import DealForm from "../Pages/Deal/DealMultiForm";
import Dashboard from "../Pages/Dashboard";

//? Paths Constant
import Paths from "../components/constants/Path";
import SuccessAlert from "../components/Alert/SuccessAlert";
import ErrorAlert from "../components/Alert/ErrorAlert";
import L2ListOfDeal from "../Pages/L2/Deals/ListOfDeals";
import L2ViewDealDetails from "../Pages/L2/Deals/ViewDealDetails";
import L2Home from "../Pages/L2/OriginatorUpdKYC.jsx/Home";
import CompleteKYC from "../Pages/L2/OriginatorUpdKYC.jsx/CompleteKYC";
import OriginatorDealList from "../Pages/L2/OriginatorUpdKYC.jsx/OriginatorDealList";
import L2OriginatorsList from "../Pages/L2/Originator/L2OriginatorsList";
import L2OriginatorsTabs from "../Pages/L2/Originator/L2OriginatorsTabs";
import Home from "../Pages/Phase2/Home";
import UploadNewReport from "../Pages/Phase2/F1/UploadNewReport";
import ListOfReports from "../Pages/Phase2/F2/ListOfReports";
import ViewReportsDetails from "../Pages/Phase2/F2/ViewReportsDetails";
import GenerateReports from "../Pages/Phase2/SearchReports/GenerateReports";
import MonthEndUploads from "../Pages/Phase2/F2/MonthEndUploads";
import MonthEndUploadList from "../Pages/Phase2/F2/MonthEndUploadList";
import RedirectToLoginPage from "../Pages/redirect";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Notifications from "../components/Phase2/Notification/Notifications";
import NotificationsPage from "../Pages/NotificationsPage";
import { isAuthenticated } from "../helper/AuthHelper";
import OriginatorDealDetails from "../Pages/L2/OriginatorUpdPool/OriginatorDealDetails";
import OriginatorCreditDealList from "../Pages/L2/OriginatorCreditBureau/OriginatorCreditDealList";
import OriginatorCreditUpload from "../Pages/L2/OriginatorCreditBureau/OriginatorCreditUpload";
import OriginatorKYCLinkDealist from "../Pages/L2/OriginatorKYCLink/OriginatorKYCLinkDealist";
import OriginatorKYCLinkUpdate from "../Pages/L2/OriginatorKYCLink/OriginatorKYCLinkUpdate";
import AuditorDealList from "../Pages/Auditor/AuditorDealList";
import AuditorKYCApproveReject from "../Pages/Auditor/AuditorKYCApproveReject";
import ApproverDealist from "../Pages/Approver/ApproveRejectEntireDeal/ApproverDealist";
import ApproverViewDealDetails from "../Pages/Approver/ApproveRejectEntireDeal/ApproverViewDealDetails";
import ApproverDocUpdDealist from "../Pages/Approver/UploadDocuments/ApproverDocUpdDealist";
import ApproverUpdDocs from "../Pages/Approver/UploadDocuments/ApproverUpdDocs";
import POOLSHORTLISTING from "../Pages/SecondaryCheckFlow/POOLSHORTLISTING";
import PoolShortListingDealist from "../Pages/SecondaryCheckFlow/PoolShortListingDealist";
import EditOriginatorDealist from "../Pages/EditOriginatorFlow/EditOriginatorDealist";
import EditOriginatorViewDetails from "../Pages/EditOriginatorFlow/EditOriginatorViewDetails";
import CREDITBUREAUREPORTDEALIST from "../Pages/L1KYCDocumentsRequest/CREDITBUREAUREPORTDEALIST";
import KYCDocumentsRequest from "../Pages/L1KYCDocumentsRequest/KYCDocumentsRequest";
import CONDUCTPOOLSHORTLISTING from "../Pages/SecondaryCheckFlow/CONDUCTPOOLSHORTLISTING";
import L2LoanDeatils from "../Pages/L2/Loans/L2LoanDetails";
import L2LoanDealist from "../Pages/L2/Loans/L2LoanDealist";
import DealDuplicationDealist from "../Pages/DealDuplication/DealDuplicationDealist";
import DealDuplicationDetails from "../Pages/DealDuplication/DealDuplicationDetails";
import DealDuplication from "../Pages/DealDuplication/DealDuplication";
import OriginatorLoanUploadSummary from "../Pages/L2/OriginatorUpdPool/OriginatorLoanUploadSummary";
import L2CreditFiltersDealist from "../Pages/L2/CreditFilters/L2CreditFiltersDealist";
import L2CreditFilterDetails from "../Pages/L2/CreditFilters/L2CreditFilterDetails";
import VoucherDetails from "./../Pages/Phase2/F2/ViewVoucherDetails";
import ViewSoaReport from "../Pages/Phase2/F2/ViewSOAReport";
import AuditorKYCLoanUploadSummary from "../Pages/Auditor/AuditorKYCLoanUploadSummary";
import PortfolioFinalDealList from "../Pages/L1FinalDeal/PortfolioDealList";
import PortfolioViewDetails from "../Pages/L1FinalDeal/PortfolioViewDetails";
import PortfolioFinalViewDealDeatils from "../Pages/L1FinalDeal/PortfolioFinalViewDealDeatils";
import PortfolioFinalLoanDeatils from "../Pages/L1FinalDeal/PortfolioFinalLoanDeatils";
import EntityDealList from "../Pages/L2/Originator/EntityDealList";
import CreateEntity from "../Pages/L2/Originator/createEntity";
import EntityViewDealDeatils from "../Pages/L2/Originator/EntityViewDealDetails";
import EntityLoanDetails from "../Pages/L2/Originator/EntityLoanDetails";
import TermSheetRequest from "../Pages/L1TermSheet/TermSheetRequest";
import DownloadReport from "../Pages/DownloadReport";
import { withAuthorization } from "../utils/helper";
import Unauthorized from "./Unauthorized";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import ListOfOriginatorProfile from "../Pages/Originator/ListOfOriginatorProfile";
import OriginatorProfileDetails from "../Pages/L2/Originator/OriginatorProfileDetails";
import F1ListOfDeal from "../Pages/Phase2/F1/F1ListOfDeal";
import F1DealDetails from "../Pages/Deal/F1DealDetails";
import ListOfMonthEndDeals from "../Pages/Phase2/F2/ListOfMonthEndDeals";
import DailyDPDRequest from "../Pages/DailyDPD/DailyDPDRequest";


const routes = [
  {
    path: Paths.DASHBOARD,
    component: Dashboard,
    isPrivate: true,
  },

  {
    path: Paths.ORIGINATOR_LIST,
    component: ListOfOriginators,
    isPrivate: true,
    role: "L1",
  },
  {
    path: Paths.CREATE_DEAL,
    component: CreateDeal,
    isPrivate: true,
    role: "L1",
  },
  {
    path: Paths.CREATE_ORIGINATOR,
    component: CreateOriginator,
    isPrivate: true,
    role: "L1",
  },
  {
    path: Paths.ORIGINATOR_FORM,
    component: OriginatorForm,
    isPrivate: true,
    role: "L1",
  },

  {
    path: Paths.DEAL_FORM,
    component: DealForm,
    isPrivate: true,
    role: "L1",
  },
  {
    path: Paths.DEALS_LIST,
    component: ListOfDeal,
    isPrivate: true,
    role: "L1",
  },
  {
    path: Paths.VIEW_DETAILS,
    component: ViewDealDetails,
    isPrivate: true,
    role: "L1",
  },
  {
    path: Paths.ORIGINATOR_DETAILS,
    component: ViewDealDetails,
    isPrivate: true,
    role: "L1",
  },
  {
    path: Paths.CONDUCTPOOLSHORTLISTING,
    component: CONDUCTPOOLSHORTLISTING,
    isPrivate: true,
    role: "L1",
  },
  {
    path: Paths.POOLSHORTLISTING,
    component: POOLSHORTLISTING,
    isPrivate: true,
    role: "L1",
  },
  {
    path: Paths.POOLSHORTLISTINGDEALIST,
    component: PoolShortListingDealist,
    isPrivate: true,
    role: "L1",
  },

  {
    path: Paths.L2DEALLIST,
    component: L2ListOfDeal,
    isPrivate: true,
    role: "L2",
  },
  {
    path: Paths.L2VIEWDETAILS,
    component: L2ViewDealDetails,
    isPrivate: true,
    role: "L2",
  },

  {
    path: Paths.ORIGINATORHOME,
    component: L2Home,
    isPrivate: true,
    role: "Originator",
  },

  {
    path: Paths.L2OIRIGINATORSDETAILS,
    component: L2OriginatorsTabs,
    isPrivate: true,
    role: "L2",
  },
  {
    path: Paths.L2OIRIGINATORSLIST,
    component: L2OriginatorsList,
    isPrivate: true,
    role: "L2",
  },
  {
    path: Paths.L2LOANDEALIST,
    component: L2LoanDealist,
    isPrivate: true,
    role: "L2",
  },
  {
    path: Paths.L2LOANDETAILS,
    component: L2LoanDeatils,
    isPrivate: true,
    role: "L2",
  },

  {
    path: Paths.EDITORIGINATOR,
    component: EditOriginatorDealist,
    isPrivate: true,
    role: "L1",
  },
  {
    path: Paths.VIEW_EDITORIGINATOR,
    component: EditOriginatorViewDetails,
    isPrivate: true,
    role: "L1",
  },
  {
    path: Paths.CREDITBUREAUREPORTDEALIST,
    component: CREDITBUREAUREPORTDEALIST,
    isPrivate: true,
    role: "L1",
  },
  {
    path: Paths.KYCDOCUMENTSREQUEST,
    component: KYCDocumentsRequest,
    isPrivate: true,
    role: "L1",
  },
  {
    path: Paths.DEALDUPLICATIONDEALIST,
    component: DealDuplicationDealist,
    isPrivate: true,
    role: "L1",
  },
  {
    path: Paths.DEALDUPLICATIONDETAILS,
    component: DealDuplicationDetails,
    isPrivate: true,
    role: "L1",
  },
  {
    path: Paths.DEALDUPLICATION,
    component: DealDuplication,
    isPrivate: true,
    role: "L1",
  },

  {
    path: Paths.ORIGINATORCOMPLETEKYC,
    component: CompleteKYC,
    isPrivate: true,
    role: "Originator",
  },
  {
    path: Paths.ORIGINATORPROFILELIST,
    component: ListOfOriginatorProfile,
    isPrivate: true,
    role: "Originator",
  },
  {
    path: Paths.ORIGINATORPROFILETABLE,
    component: OriginatorProfileDetails,
    isPrivate: true,
    role: "Originator",
  },

  {
    path: Paths.ORIGINATORDEALLIST,
    component: OriginatorDealList,
    isPrivate: true,
    role: "Originator",
  },

  {
    path: Paths.EntityDealList,
    component: EntityDealList,
    isPrivate: true,
    role: "Originator",
  },

  {
    path: Paths.CreateEntity,
    component: CreateEntity,
    isPrivate: true,
    role: "Originator",
  },

  {
    path: Paths.EntityDealDeatils,
    component: EntityViewDealDeatils,
    isPrivate: true,
    role: "Originator",
  },

  {
    path: Paths.EntityLoanDeatils,
    component: EntityLoanDetails,
    isPrivate: true,
    role: "Originator",
  },

  {
    path: Paths.PORTFOLIOFINALDEALLIST,
    component: PortfolioFinalDealList,
    isPrivate: true,
    role: ["L1", "L2", "C1", "R1", "CP1", "IA1", "CO1"],
  },

  {
    path: Paths.PORTFOLIOFINALLOANDETAILS,
    component: PortfolioFinalLoanDeatils,
    isPrivate: true,
    role: ["L1", "L2"],
  },
  {
    path: Paths.PORTFOLIOFINALVIEWDEALDETAILS,
    component: PortfolioFinalViewDealDeatils,
    isPrivate: true,
    role: ["L1", "L2", "C1", "R1", "CP1", "IA1", "CO1"],
  },
  {
    path: Paths.PORTFOLIOVIEWDETAILS,
    component: PortfolioViewDetails,
    isPrivate: true,
    role: "L1",
  },

  {
    path: Paths.PRIMARYCHECKDEALLIST,
    component: OriginatorDealList,
    isPrivate: true,
    role: "Originator",
  },

  //Phase 2 Routes
  {
    path: Paths.PHASE1HOME,
    component: Home,
    isPrivate: true,
    role: ["F1", "F2"],
  },
  {
    path: Paths.PHASE2UPLOADNEWREPORT,
    component: UploadNewReport,
    isPrivate: true,
    role: "F1",
  },
  {
    path: Paths.F1LISTOFDEAL,
    component: F1ListOfDeal,
    isPrivate: true,
    role: "F1",
  },

  {
    path: Paths.F1DEALDETAILS,
    component: F1DealDetails,
    isPrivate: true,
    role: "F1",
  },

  {
    path: Paths.LISTOFREPORTS,
    component: ListOfReports,
    isPrivate: true,
    role: ["F1", "F2"],
  },
  {
    path: Paths.REPORTS,
    component: ViewReportsDetails,
    isPrivate: true,
    role: ["F1", "F2"],
  },
  {
    path: Paths.GENERATEREPORT,
    component: GenerateReports,
    isPrivate: true,
    role: ["F1", "F2"],
  },
  {
    path: Paths.MONTH_END_UPLOADS,
    component: MonthEndUploads,
    isPrivate: true,
    role: "F2",
  },
  {
    path: Paths.MONTH_END_DEALS,
    component: ListOfMonthEndDeals,
    isPrivate: true,
    role: "F2",
  },
  {
    path: Paths.MONTH_END_UPLOAD_VIEW,
    component: MonthEndUploadList,
    isPrivate: true,
    role: "F2",
  },
  {
    path: Paths.VIEW_VOUCHER,
    component: VoucherDetails,
    isPrivate: true,
    role: ["F1", "F2"],
  },
  {
    path: Paths.VIEW_SOA_REPORT,
    component: ViewSoaReport,
    isPrivate: true,
    role: ["F1", "F2"],
  },
  {
    path: Paths.NOTIFICATIONS,
    component: NotificationsPage,
    isPrivate: true,
  },

  {
    path: "/login",
    component: Login,
    isPrivate: false,
  },
  {
    path: "/",
    component: Login,
    isPrivate: false,
  },


  // {
  //   path: "/logout",
  //   component: Login,
  //   isPrivate: false,
  // },
  {
    path: Paths.ORIGINATORUPLOADPOOL,
    component: OriginatorDealDetails,
    isPrivate: true,
    role: "Originator",
  },
  {
    path: Paths.ORIGINATORLOANUPDSUMMARY,
    component: OriginatorLoanUploadSummary,
    isPrivate: true,
    role: "Originator",
  },
  
  {
    path: Paths.ORIGINATORCREDITDEALSLIST,
    component: OriginatorCreditDealList,
    isPrivate: true,
    role: "Originator",
  },
  {
    path: Paths.ORIGINATORCREDITUPLOAD,
    component: OriginatorCreditUpload,
    isPrivate: true,
    role: "Originator",
  },
  {
    path: Paths.ORIGINATORKYCLINKDEALLIST,
    component: OriginatorKYCLinkDealist,
    isPrivate: true,
    role: "Originator",
  },
  {
    path: Paths.ORIGINATORKYCLINKUPDATE,
    component: OriginatorKYCLinkUpdate,
    isPrivate:  true,
    role: "Originator",
  },
  {
    path: Paths.AUDITORDEALIST,
    component: AuditorDealList,
    isPrivate:  true,
    role: "Auditor",
  },
  {
    path: Paths.AUDITORKYCAPPROVEREJECT,
    component: AuditorKYCApproveReject,
    isPrivate: true,
    role: "Auditor",
  },
  {
    path: Paths.AUDITORLOANUPDSUMMARY,
    component: AuditorKYCLoanUploadSummary,
    isPrivate: true,
    role: "Auditor",
  },
  {
    path: Paths.APPROVERDEALIST,
    component: ApproverDealist,
    isPrivate: true,
    role: ["Approver 1", "Approver 2", "Approver 3", "Approver 4", "Approver 5","Approver 6", "Approver 7"],
  },
  {
    path: Paths.APPROVERVIEWDEALDETAILS,
    component: ApproverViewDealDetails,
    isPrivate: true,
    role: ["Approver 1", "Approver 2", "Approver 3", "Approver 4", "Approver 5","Approver 6", "Approver 7"],
  },
  {
    path: Paths.APPROVERDOCUMENTUPDDEALIST,
    component: ApproverDocUpdDealist,
    isPrivate: true,
    role: ["Approver 1", "Approver 2", "Approver 3", "Approver 4", "Approver 5","Approver 6", "Approver 7"],
  },
  {
    path: Paths.APPROVERUPDDOC,
    component: ApproverUpdDocs,
    isPrivate: true,
    role: ["Approver 1", "Approver 2", "Approver 3", "Approver 4", "Approver 5","Approver 6", "Approver 7"],
  },
  {
    path: Paths.L2CREDITFILTERSDEALIST,
    component: L2CreditFiltersDealist,
    isPrivate: true,
    role: "L2",
  },

  {
    path: Paths.L2CREDITFILTERSDETAILS,
    component: L2CreditFilterDetails,
    isPrivate: true,
    role: "L2",
  },
  {
    path: Paths.TERM_SHEET,
    component: TermSheetRequest,
    isPrivate: true,
    role: ["L1", "L2", "Originator"],
  },
  {
    path: Paths.DAILY_DPD,
    component: DailyDPDRequest,
    isPrivate: true,
    role: "Originator",
  },
  {
    path: Paths.DOWNLOAD_REPORT,
    component: DownloadReport,
    isPrivate: true,
  },
  {
    path: Paths.SWAYAMUSERDEALLIST,
    component: ListOfDeal,
    isPrivate: true,
    role: ["C1", "R1", "CP1", "IA1", "CO1"]
  },
  {
    path: Paths.SWAYAMUSERDEALDETAILS,
    component: ViewDealDetails,
    isPrivate: true,
    role: ["C1", "R1", "CP1", "IA1", "CO1"],
  },
];

const AllRoutes = () => {
  let { redirectToLogin } = useSelector((state) => state.app);
  let history = useHistory();
  const generateRoutes = () => {
    const userData = JSON.parse(localStorage.getItem("UserData"));
    const userRoles = userData?.roles;

    return routes?.map((page) => {
      const { path, component, isPrivate, role } = page;
      let finalRoles = [];
      // Check if role is Array or not
      if (role && !Array.isArray(role)) {
        finalRoles.push(role);
      } else {
        finalRoles = role;
      }
      // Check if finalRoles array elements are present in userRoles  array
      const isRolePresent = finalRoles?.some((r) => userRoles?.includes(r));
      console.log({ isRolePresent, finalRoles, userRoles });
      if (isPrivate) {
        if (isRolePresent || !role) {
          return (
            <PrivateRoute
              exact
              key={path}
              path={path}
              component={component}
              redirectToLogin={redirectToLogin}
            />
          );
        } 
        else {
          return (
            <Route
              key={path}
              path={path}
              render={() => <Redirect to={Paths?.UNAUTHORIZED} />
            }
            />
          );
        }
      } else {
        return (
          <Route key={path} exact={true} path={path} component={component} />
        );
      }
    });
  };

  return (
    <>
      <SuccessAlert />
      <ErrorAlert />
      <BrowserRouter>
        <Switch>
          {isAuthenticated("Token") ? (
            generateRoutes()
          ) : (
            <Route path={Paths.LOGIN} component={Login} />
          )}
          <Route
            path={Paths.UNAUTHORIZED}
            exact={true}
            component={Unauthorized}
          />
          <Route path="*" exact={true} component={Unauthorized} />
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default AllRoutes;

import React from "react";
import WrapperComponent from "../../components/Wrapper/WrapperComponent";
import { Grid, Typography, TextField, Divider } from "@mui/material";
import { useEffect } from "react";
import { goForUpdateDeal } from "../../redux/L1/finalDealSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import Loader from "./../../utils/Loader";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  showAlertAction,
  showErrorAlertAction,
} from "../../redux/app/appSlice";
import OutlineButton from "./../../components/Buttons/OutlineButton";
import { postCommentReply } from "../../redux/Queries/queriesSlice";
import {
  getDealDocuments,
  clearAllDocuments,
  getSingleDealDetails,
} from "../../redux/Deals/dealSlice";
import AllDocuments from "../../components/AllDocuments";
import AllDocumentsV2 from "../../components/AllDocumentsV2";
import { statusConstants } from "../../components/constants/FiltersConstant";
import DetailsData from "../../components/DetailsData";

const ReadOnlyFinalLoanDetails = () => {
  let dispatch = useDispatch();
  const [query, setQuery] = useState("");

  const params = useParams();
  const dealState = useSelector((state) => state.deal);

  let userData = JSON.parse(localStorage.getItem("UserData"));

  useEffect(() => {
    dispatch(getDealDocuments({ dealId: params.dealId }));
  }, []);

  function onPostClickHandler(commentId, query) {
    console.log({ commentId, query });
    dispatch(postCommentReply({ query, commentId })).then((res) => {
      dispatch(getSingleDealDetails(params.dealId));
    });
  }

  useEffect(() => {
    return () => {
      dispatch(clearAllDocuments());
    };
  }, []);

  const DataComp = ({ title, desc }) => {
    return (
      <div>
        <Typography fontSize="14px" fontWeight="400" color="#808080">
          {title}
        </Typography>
        <Typography fontSize="14px" fontWeight="400">
          {desc}
        </Typography>
      </div>
    );
  };

  return (
    <>
      {dealState?.loading ? (
        <Loader />
      ) : (
        <div
          style={{
            paddingTop: "14px",
          }}
        >
          {dealState?.status == statusConstants.CLOSED && dealState?.filterdata?.isMigrated === true ? (
            <AllDocumentsV2 />
          ) : (
            <AllDocuments />
          )}
          {dealState?.status !== statusConstants.CLOSED && userData?.roles.length == 1 && (
            <>
              <DetailsData label="Financial Details" />
            
              <WrapperComponent title="Approval Committee Setup">
                <>
                  <br></br>

                  <Grid container spacing={3}>
                    {dealState?.filterdata?.approvers &&
                      dealState?.filterdata?.approvers.map((item, index) => {
                        return (
                          <Grid item xs={3}>
                            <DataComp
                              title={item.roleName}
                              desc={item.name}
                            ></DataComp>
                          </Grid>
                        );
                      })}
                  </Grid>
                </>
              </WrapperComponent>
              <br></br>
              {dealState?.filterdata?.comments !== undefined && (
                <>
                  {dealState?.filterdata?.comments["Approver 1"] !==
                    undefined &&
                    dealState?.filterdata?.comments["Approver 1"].length >
                    0 && (
                      <>
                        {dealState?.filterdata?.comments["Approver 1"].map(
                          (reply, index) => {
                            return (
                              <>
                                <WrapperComponent
                                  title={`Reply for Approver's 1 Comment:- ${reply?.query}`}
                                >
                                  <>
                                    <div
                                      style={{
                                        padding: "14px",
                                        marginTop: "19px",
                                      }}
                                    >
                                      <Grid container spacing={0}>
                                        {reply?.replies &&
                                          reply.replies.map((data) => {
                                            const roleName =
                                              data?.roleName == undefined ||
                                                data?.roleName == null
                                                ? ""
                                                : data?.roleName + " Reply";
                                            return (
                                              <Grid data xs={6}>
                                                {data?.raisedById == null ? (
                                                  ""
                                                ) : (
                                                  <DataComp
                                                    title={roleName}
                                                    desc={data?.query}
                                                  />
                                                )}
                                              </Grid>
                                            );
                                          })}
                                        <div
                                          style={{
                                            justifyContent: "space-between",
                                            alignItems: "space-between",
                                            display: "flex",
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "700px",
                                            }}
                                          >
                                            <TextField
                                              variant="standard"
                                              fullWidth
                                              autoComplete="off"
                                              onChange={(e) =>
                                                setQuery(e.target.value)
                                              }
                                            ></TextField>
                                          </div>
                                          <div style={{ margin: "0px 10px" }}>
                                            <OutlineButton
                                              text="Reply"
                                              showIcon={false}
                                              onClickHandler={() =>
                                                onPostClickHandler(
                                                  reply._id,
                                                  query
                                                )
                                              }
                                            ></OutlineButton>
                                          </div>
                                        </div>
                                      </Grid>
                                    </div>
                                  </>
                                </WrapperComponent>
                              </>
                            );
                          }
                        )}
                      </>
                    )}

                  {dealState?.filterdata?.comments &&
                    dealState?.filterdata?.comments["Approver 2"] !==
                    undefined &&
                    dealState?.filterdata?.comments["Approver 2"].length >
                    0 && (
                      <>
                        {dealState?.filterdata?.comments["Approver 2"].map(
                          (reply, index) => {
                            const roleName =
                              reply?.roleName == undefined ||
                                reply?.roleName == null
                                ? ""
                                : reply?.roleName + " Reply";
                            return (
                              <>
                                <WrapperComponent
                                  title={`Reply for Approver's 2 Comment:- ${reply?.query}`}
                                >
                                  <>
                                    <div
                                      style={{
                                        padding: "14px",
                                        marginTop: "19px",
                                      }}
                                    >
                                      <Grid container spacing={0}>
                                        {reply?.replies &&
                                          reply.replies.map((data) => {
                                            const roleName =
                                              data?.roleName == undefined ||
                                                data?.roleName == null
                                                ? ""
                                                : data?.roleName + " Reply";
                                            return (
                                              <Grid data xs={6}>
                                                {data?.raisedById == null ? (
                                                  ""
                                                ) : (
                                                  <DataComp
                                                    title={roleName}
                                                    desc={data?.query}
                                                  />
                                                )}
                                              </Grid>
                                            );
                                          })}
                                        <div
                                          style={{
                                            justifyContent: "space-between",
                                            alignItems: "space-between",
                                            display: "flex",
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "700px",
                                            }}
                                          >
                                            <TextField
                                              variant="standard"
                                              fullWidth
                                              autoComplete="off"
                                              onChange={(e) =>
                                                setQuery(e.target.value)
                                              }
                                            ></TextField>
                                          </div>
                                          <div style={{ margin: "0px 10px" }}>
                                            <OutlineButton
                                              text="Reply"
                                              showIcon={false}
                                              onClickHandler={() =>
                                                onPostClickHandler(
                                                  reply._id,
                                                  query
                                                )
                                              }
                                            ></OutlineButton>
                                          </div>
                                        </div>
                                      </Grid>
                                    </div>
                                  </>
                                </WrapperComponent>
                              </>
                            );
                          }
                        )}
                      </>
                    )}

                  {dealState?.filterdata?.comments &&
                    dealState?.filterdata?.comments["Approver 3"] !==
                    undefined &&
                    dealState?.filterdata?.comments["Approver 3"].length >
                    0 && (
                      <>
                        {dealState?.filterdata?.comments["Approver 3"].map(
                          (reply, index) => {
                            return (
                              <>
                                <WrapperComponent
                                  title={`Reply for Approver's 3 Comment:- ${reply?.query}`}
                                >
                                  <>
                                    <div
                                      style={{
                                        padding: "14px",
                                        marginTop: "19px",
                                      }}
                                    >
                                      <Grid container spacing={0}>
                                        {reply?.replies &&
                                          reply.replies.map((data) => {
                                            const roleName =
                                              data?.roleName == undefined ||
                                                data?.roleName == null
                                                ? ""
                                                : data?.roleName + " Reply";

                                            return (
                                              <Grid data xs={6}>
                                                {data?.raisedById == null ? (
                                                  ""
                                                ) : (
                                                  <DataComp
                                                    title={roleName}
                                                    desc={data?.query}
                                                  />
                                                )}
                                              </Grid>
                                            );
                                          })}
                                        <div
                                          style={{
                                            justifyContent: "space-between",
                                            alignItems: "space-between",
                                            display: "flex",
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "700px",
                                            }}
                                          >
                                            <TextField
                                              variant="standard"
                                              fullWidth
                                              autoComplete="off"
                                              onChange={(e) =>
                                                setQuery(e.target.value)
                                              }
                                            ></TextField>
                                          </div>
                                          <div style={{ margin: "0px 10px" }}>
                                            <OutlineButton
                                              text="Reply"
                                              showIcon={false}
                                              onClickHandler={() =>
                                                onPostClickHandler(
                                                  reply._id,
                                                  query
                                                )
                                              }
                                            // onClickHandler={onAcceptClickHandler}
                                            ></OutlineButton>
                                          </div>
                                        </div>
                                      </Grid>
                                    </div>
                                  </>
                                </WrapperComponent>
                              </>
                            );
                          }
                        )}
                      </>
                    )}

                  {dealState?.filterdata?.comments &&
                    dealState?.filterdata?.comments["Approver 4"] !==
                    undefined &&
                    dealState?.filterdata?.comments["Approver 4"].length >
                    0 && (
                      <>
                        {dealState?.filterdata?.comments["Approver 4"].map(
                          (reply, index) => {
                            return (
                              <>
                                <WrapperComponent
                                  title={`Reply for Approver's 4 Comment:- ${reply?.query}`}
                                >
                                  <>
                                    <div
                                      style={{
                                        padding: "14px",
                                        marginTop: "19px",
                                      }}
                                    >
                                      <Grid container spacing={0}>
                                        {reply?.replies &&
                                          reply.replies.map((data) => {
                                            const roleName =
                                              data?.roleName == undefined ||
                                                data?.roleName == null
                                                ? ""
                                                : data?.roleName + " Reply";
                                            return (
                                              <Grid data xs={6}>
                                                {data?.raisedById == null ? (
                                                  ""
                                                ) : (
                                                  <DataComp
                                                    title={roleName}
                                                    desc={data?.query}
                                                  />
                                                )}
                                              </Grid>
                                            );
                                          })}
                                        <div
                                          style={{
                                            justifyContent: "space-between",
                                            alignItems: "space-between",
                                            display: "flex",
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "700px",
                                            }}
                                          >
                                            <TextField
                                              variant="standard"
                                              fullWidth
                                              autoComplete="off"
                                              onChange={(e) =>
                                                setQuery(e.target.value)
                                              }
                                            ></TextField>
                                          </div>
                                          <div style={{ margin: "0px 10px" }}>
                                            <OutlineButton
                                              text="Reply"
                                              showIcon={false}
                                              onClickHandler={() =>
                                                onPostClickHandler(
                                                  reply._id,
                                                  query
                                                )
                                              }
                                            ></OutlineButton>
                                          </div>
                                        </div>
                                      </Grid>
                                    </div>
                                  </>
                                </WrapperComponent>
                              </>
                            );
                          }
                        )}
                      </>
                    )}

                  {dealState?.filterdata?.comments &&
                    dealState?.filterdata?.comments["Approver 5"] !==
                    undefined &&
                    dealState?.filterdata?.comments["Approver 5"].length >
                    0 && (
                      <>
                        {dealState?.filterdata?.comments["Approver 5"].map(
                          (reply, index) => {
                            return (
                              <>
                                <WrapperComponent
                                  title={`Reply for Approver's 5 Comment:- ${reply?.query}`}
                                >
                                  <>
                                    <div
                                      style={{
                                        padding: "14px",
                                        marginTop: "19px",
                                      }}
                                    >
                                      <Grid container spacing={0}>
                                        {reply?.replies &&
                                          reply.replies.map((data) => {
                                            const roleName =
                                              data?.roleName == undefined ||
                                                data?.roleName == null
                                                ? ""
                                                : data?.roleName + " Reply";
                                            return (
                                              <Grid data xs={6}>
                                                {data?.raisedById == null ? (
                                                  ""
                                                ) : (
                                                  <DataComp
                                                    title={roleName}
                                                    desc={data?.query}
                                                  />
                                                )}
                                              </Grid>
                                            );
                                          })}
                                        <div
                                          style={{
                                            justifyContent: "space-between",
                                            alignItems: "space-between",
                                            display: "flex",
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "700px",
                                            }}
                                          >
                                            <TextField
                                              variant="standard"
                                              fullWidth
                                              autoComplete="off"
                                              onChange={(e) =>
                                                setQuery(e.target.value)
                                              }
                                            ></TextField>
                                          </div>
                                          <div style={{ margin: "0px 10px" }}>
                                            <OutlineButton
                                              text="Reply"
                                              showIcon={false}
                                              onClickHandler={() =>
                                                onPostClickHandler(
                                                  reply._id,
                                                  query
                                                )
                                              }
                                            // onClickHandler={onAcceptClickHandler}
                                            ></OutlineButton>
                                          </div>
                                        </div>
                                      </Grid>
                                    </div>
                                  </>
                                </WrapperComponent>
                              </>
                            );
                          }
                        )}
                      </>
                    )}

                  {dealState?.filterdata?.comments &&
                    dealState?.filterdata?.comments["Approver 6"] !==
                    undefined &&
                    dealState?.filterdata?.comments["Approver 6"].length >
                    0 && (
                      <>
                        {dealState?.filterdata?.comments["Approver 6"].map(
                          (reply, index) => {
                            return (
                              <>
                                <WrapperComponent
                                  title={`Reply for Approver's 6 Comment:- ${reply?.query}`}
                                >
                                  <>
                                    <div
                                      style={{
                                        padding: "14px",
                                        marginTop: "19px",
                                      }}
                                    >
                                      <Grid container spacing={0}>
                                        {reply?.replies &&
                                          reply.replies.map((data) => {
                                            const roleName =
                                              data?.roleName == undefined ||
                                                data?.roleName == null
                                                ? ""
                                                : data?.roleName + " Reply";
                                            return (
                                              <Grid data xs={6}>
                                                {data?.raisedById == null ? (
                                                  ""
                                                ) : (
                                                  <DataComp
                                                    title={roleName}
                                                    desc={data?.query}
                                                  />
                                                )}
                                              </Grid>
                                            );
                                          })}
                                        <div
                                          style={{
                                            justifyContent: "space-between",
                                            alignItems: "space-between",
                                            display: "flex",
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "700px",
                                            }}
                                          >
                                            <TextField
                                              variant="standard"
                                              fullWidth
                                              autoComplete="off"
                                              onChange={(e) =>
                                                setQuery(e.target.value)
                                              }
                                            ></TextField>
                                          </div>
                                          <div style={{ margin: "0px 10px" }}>
                                            <OutlineButton
                                              text="Reply"
                                              showIcon={false}
                                              onClickHandler={() =>
                                                onPostClickHandler(
                                                  reply._id,
                                                  query
                                                )
                                              }
                                            // onClickHandler={onAcceptClickHandler}
                                            ></OutlineButton>
                                          </div>
                                        </div>
                                      </Grid>
                                    </div>
                                  </>
                                </WrapperComponent>
                              </>
                            );
                          }
                        )}
                      </>
                    )}

                  {dealState?.filterdata?.comments &&
                    dealState?.filterdata?.comments["Approver 7"] !==
                    undefined &&
                    dealState?.filterdata?.comments["Approver 7"].length >
                    0 && (
                      <>
                        {dealState?.filterdata?.comments["Approver 7"].map(
                          (reply, index) => {
                            //
                            return (
                              <>
                                <WrapperComponent
                                  title={`Reply for Approver's 7 Comment:- ${reply?.query}`}
                                >
                                  <>
                                    <div
                                      style={{
                                        padding: "14px",
                                        marginTop: "19px",
                                      }}
                                    >
                                      <Grid container spacing={0}>
                                        {reply?.replies &&
                                          reply.replies.map((data) => {
                                            const roleName =
                                              data?.roleName == undefined ||
                                                data?.roleName == null
                                                ? ""
                                                : data?.roleName + " Reply";
                                            return (
                                              <Grid data xs={6}>
                                                {data?.raisedById == null ? (
                                                  ""
                                                ) : (
                                                  <DataComp
                                                    title={roleName}
                                                    desc={data?.query}
                                                  />
                                                )}
                                              </Grid>
                                            );
                                          })}
                                        <div
                                          style={{
                                            justifyContent: "space-between",
                                            alignItems: "space-between",
                                            display: "flex",
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "700px",
                                            }}
                                          >
                                            <TextField
                                              variant="standard"
                                              fullWidth
                                              autoComplete="off"
                                              onChange={(e) =>
                                                setQuery(e.target.value)
                                              }
                                            ></TextField>
                                          </div>
                                          <div style={{ margin: "0px 10px" }}>
                                            <OutlineButton
                                              text="Reply"
                                              showIcon={false}
                                              onClickHandler={() =>
                                                onPostClickHandler(
                                                  reply._id,
                                                  query
                                                )
                                              }
                                            // onClickHandler={onAcceptClickHandler}
                                            ></OutlineButton>
                                          </div>
                                        </div>
                                      </Grid>
                                    </div>
                                  </>
                                </WrapperComponent>
                              </>
                            );
                          }
                        )}
                      </>
                    )}
                </>
              )}
            </>
          )}

          {userData?.roles.length != 1 && (
            <>

              <DetailsData label="Financial Details" />

              <WrapperComponent title="Approval Committee Setup">
                <>
                  <br></br>

                  <Grid container spacing={3}>
                    {dealState?.filterdata?.approvers &&
                      dealState?.filterdata?.approvers.map((item, index) => {
                        return (
                          <Grid item xs={3}>
                            <DataComp
                              title={item.roleName}
                              desc={item.name}
                            ></DataComp>
                          </Grid>
                        );
                      })}
                  </Grid>
                </>
              </WrapperComponent>

              {dealState?.filterdata?.comments !== undefined && (
                <>
                  {dealState?.filterdata?.comments["Approver 1"] !==
                    undefined &&
                    dealState?.filterdata?.comments["Approver 1"].length >
                    0 && (
                      <>
                        {dealState?.filterdata?.comments["Approver 1"].map(
                          (reply, index) => {
                            return (
                              <>
                                <WrapperComponent
                                  title={`Reply for Approver's 1 Comment:- ${reply?.query}`}
                                >
                                  <>
                                    <div
                                      style={{
                                        padding: "14px",
                                        marginTop: "19px",
                                      }}
                                    >
                                      <Grid container spacing={0}>
                                        {reply?.replies &&
                                          reply.replies.map((data) => {
                                            const roleName =
                                              data?.roleName == undefined ||
                                                data?.roleName == null
                                                ? ""
                                                : data?.roleName + " Reply";
                                            return (
                                              <Grid data xs={6}>
                                                {data?.raisedById == null ? (
                                                  ""
                                                ) : (
                                                  <DataComp
                                                    title={roleName}
                                                    desc={data?.query}
                                                  />
                                                )}
                                              </Grid>
                                            );
                                          })}
                                      </Grid>
                                    </div>
                                  </>
                                </WrapperComponent>
                              </>
                            );
                          }
                        )}
                      </>
                    )}

                  {dealState?.filterdata?.comments &&
                    dealState?.filterdata?.comments["Approver 2"] !==
                    undefined &&
                    dealState?.filterdata?.comments["Approver 2"].length >
                    0 && (
                      <>
                        {dealState?.filterdata?.comments["Approver 2"].map(
                          (reply, index) => {
                            return (
                              <>
                                <WrapperComponent
                                  title={`Reply for Approver's 2 Comment:- ${reply?.query}`}
                                >
                                  <>
                                    <div
                                      style={{
                                        padding: "14px",
                                        marginTop: "19px",
                                      }}
                                    >
                                      <Grid container spacing={0}>
                                        {reply?.replies &&
                                          reply.replies.map((data) => {
                                            const roleName =
                                              data?.roleName == undefined ||
                                                data?.roleName == null
                                                ? ""
                                                : data?.roleName + " Reply";
                                            return (
                                              <Grid data xs={6}>
                                                {data?.raisedById == null ? (
                                                  ""
                                                ) : (
                                                  <DataComp
                                                    title={roleName}
                                                    desc={data?.query}
                                                  />
                                                )}
                                              </Grid>
                                            );
                                          })}
                                      </Grid>
                                    </div>
                                  </>
                                </WrapperComponent>
                              </>
                            );
                          }
                        )}
                      </>
                    )}

                  {dealState?.filterdata?.comments &&
                    dealState?.filterdata?.comments["Approver 3"] !==
                    undefined &&
                    dealState?.filterdata?.comments["Approver 3"].length >
                    0 && (
                      <>
                        {dealState?.filterdata?.comments["Approver 3"].map(
                          (reply, index) => {
                            return (
                              <>
                                <WrapperComponent
                                  title={`Reply for Approver's 3 Comment:- ${reply?.query}`}
                                >
                                  <>
                                    <div
                                      style={{
                                        padding: "14px",
                                        marginTop: "19px",
                                      }}
                                    >
                                      <Grid container spacing={0}>
                                        {reply?.replies &&
                                          reply.replies.map((data) => {
                                            const roleName =
                                              data?.roleName == undefined ||
                                                data?.roleName == null
                                                ? ""
                                                : data?.roleName + " Reply";
                                            return (
                                              <Grid data xs={6}>
                                                {data?.raisedById == null ? (
                                                  ""
                                                ) : (
                                                  <DataComp
                                                    title={roleName}
                                                    desc={data?.query}
                                                  />
                                                )}
                                              </Grid>
                                            );
                                          })}
                                      </Grid>
                                    </div>
                                  </>
                                </WrapperComponent>
                              </>
                            );
                          }
                        )}
                      </>
                    )}

                  {dealState?.filterdata?.comments &&
                    dealState?.filterdata?.comments["Approver 4"] !==
                    undefined &&
                    dealState?.filterdata?.comments["Approver 4"].length >
                    0 && (
                      <>
                        {dealState?.filterdata?.comments["Approver 4"].map(
                          (reply, index) => {
                            return (
                              <>
                                <WrapperComponent
                                  title={`Reply for Approver's 4 Comment:- ${reply?.query}`}
                                >
                                  <>
                                    <div
                                      style={{
                                        padding: "14px",
                                        marginTop: "19px",
                                      }}
                                    >
                                      <Grid container spacing={0}>
                                        {reply?.replies &&
                                          reply.replies.map((data) => {
                                            const roleName =
                                              data?.roleName == undefined ||
                                                data?.roleName == null
                                                ? ""
                                                : data?.roleName + " Reply";
                                            return (
                                              <Grid data xs={6}>
                                                {data?.raisedById == null ? (
                                                  ""
                                                ) : (
                                                  <DataComp
                                                    title={roleName}
                                                    desc={data?.query}
                                                  />
                                                )}
                                              </Grid>
                                            );
                                          })}
                                      </Grid>
                                    </div>
                                  </>
                                </WrapperComponent>
                              </>
                            );
                          }
                        )}
                      </>
                    )}

                  {dealState?.filterdata?.comments &&
                    dealState?.filterdata?.comments["Approver 5"] !==
                    undefined &&
                    dealState?.filterdata?.comments["Approver 5"].length >
                    0 && (
                      <>
                        {dealState?.filterdata?.comments["Approver 5"].map(
                          (reply, index) => {
                            return (
                              <>
                                <WrapperComponent
                                  title={`Reply for Approver's 5 Comment:- ${reply?.query}`}
                                >
                                  <>
                                    <div
                                      style={{
                                        padding: "14px",
                                        marginTop: "19px",
                                      }}
                                    >
                                      <Grid container spacing={0}>
                                        {reply?.replies &&
                                          reply.replies.map((data) => {
                                            const roleName =
                                              data?.roleName == undefined ||
                                                data?.roleName == null
                                                ? ""
                                                : data?.roleName + " Reply";
                                            return (
                                              <Grid data xs={6}>
                                                {data?.raisedById == null ? (
                                                  ""
                                                ) : (
                                                  <DataComp
                                                    title={roleName}
                                                    desc={data?.query}
                                                  />
                                                )}
                                              </Grid>
                                            );
                                          })}
                                      </Grid>
                                    </div>
                                  </>
                                </WrapperComponent>
                              </>
                            );
                          }
                        )}
                      </>
                    )}

                  {dealState?.filterdata?.comments &&
                    dealState?.filterdata?.comments["Approver 6"] !==
                    undefined &&
                    dealState?.filterdata?.comments["Approver 6"].length >
                    0 && (
                      <>
                        {dealState?.filterdata?.comments["Approver 6"].map(
                          (reply, index) => {
                            return (
                              <>
                                <WrapperComponent
                                  title={`Reply for Approver's 6 Comment:- ${reply?.query}`}
                                >
                                  <>
                                    <div
                                      style={{
                                        padding: "14px",
                                        marginTop: "19px",
                                      }}
                                    >
                                      <Grid container spacing={0}>
                                        {reply?.replies &&
                                          reply.replies.map((data) => {
                                            const roleName =
                                              data?.roleName == undefined ||
                                                data?.roleName == null
                                                ? ""
                                                : data?.roleName + " Reply";
                                            return (
                                              <Grid data xs={6}>
                                                {data?.raisedById == null ? (
                                                  ""
                                                ) : (
                                                  <DataComp
                                                    title={roleName}
                                                    desc={data?.query}
                                                  />
                                                )}
                                              </Grid>
                                            );
                                          })}
                                      </Grid>
                                    </div>
                                  </>
                                </WrapperComponent>
                              </>
                            );
                          }
                        )}
                      </>
                    )}

                  {dealState?.filterdata?.comments &&
                    dealState?.filterdata?.comments["Approver 7"] !==
                    undefined &&
                    dealState?.filterdata?.comments["Approver 7"].length >
                    0 && (
                      <>
                        {dealState?.filterdata?.comments["Approver 7"].map(
                          (reply, index) => {
                            return (
                              <>
                                <WrapperComponent
                                  title={`Reply for Approver's 7 Comment:- ${reply?.query}`}
                                >
                                  <>
                                    <div
                                      style={{
                                        padding: "14px",
                                        marginTop: "19px",
                                      }}
                                    >
                                      <Grid container spacing={0}>
                                        {reply?.replies &&
                                          reply.replies.map((data) => {
                                            const roleName =
                                              data?.roleName == undefined ||
                                                data?.roleName == null
                                                ? ""
                                                : data?.roleName + " Reply";
                                            return (
                                              <Grid data xs={6}>
                                                {data?.raisedById == null ? (
                                                  ""
                                                ) : (
                                                  <DataComp
                                                    title={roleName}
                                                    desc={data?.query}
                                                  />
                                                )}
                                              </Grid>
                                            );
                                          })}
                                      </Grid>
                                    </div>
                                  </>
                                </WrapperComponent>
                              </>
                            );
                          }
                        )}
                      </>
                    )}

                </>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ReadOnlyFinalLoanDetails;

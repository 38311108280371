import DescriptionIcon from "@mui/icons-material/Description";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSingleDealDetails } from "../../../redux/Deals/dealSlice";
import Loader from "../../../utils/Loader";
import { DocumentHeader } from "../../DocumentHeaderWrapper/DocumentHeader";
import LabelWithDownload from "../../LabelWithDownload";
import { getDPDDocumentsHistory } from "../../../redux/DailyDPD/DailyDPDSlice";
import { Typography } from "@mui/material";

const HistoryTab = ({ nextTabHandler }) => {
    let dispatch = useDispatch();
    const [dpdSummaryData, setDPDSummarytData] = useState([]);
    const [newloader, setNewLoader] = useState(true);
    const dealState = useSelector((state) => state.deal);

    let dealid = localStorage.getItem("dealId");

    useEffect(() => {
        if (dealState?.filterdata) {
            let finalId = dealState?.filterdata?._id;
            setNewLoader(false)
            dispatch(getDPDDocumentsHistory({ dealId: finalId })).then(
                (res) => {
                    const data = res?.payload?.data || [];
                    setDPDSummarytData(data);
                }
            );
        }
    }, [dealState?.filterdata]);


    useEffect(() => {
        dispatch(getSingleDealDetails(dealid))
    }, []);

    console.log({ dpdSummaryData });

    return (
        <>
            {newloader ? (
                <Loader />
            ) : (
                <>
                    {dpdSummaryData.length > 0 ? (
                        dpdSummaryData.map((file) => {
                            const uploadDate = file?.createdAt;
                            const date = new Date(uploadDate);
                            const formatDate = date.toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "long",
                                year: "numeric",
                            });
                            const formatTime = date.toLocaleTimeString("en-GB", {
                                hour: "2-digit",
                                minute: "2-digit",
                            });
                            return (

                                <>
                                    <DocumentHeader
                                        title={`Uploaded At ${formatDate}, ${formatTime}`}
                                        startIcon={
                                            <DescriptionIcon
                                                sx={{ color: "#5E7387", width: "20px", height: "20px" }}
                                            />
                                        }
                                    ></DocumentHeader>
                                    <div
                                        style={{
                                            padding: "24px",
                                        }}
                                    >
                                        <LabelWithDownload
                                            // label="uploaded File "
                                            fileId={file?.uploadedFile?._id || ""}
                                            fileName={file?.uploadedFile?.filename || ""}
                                        />
                                    </div>
                                </>
                            )
                        })) : (
                        <Typography>No data available.</Typography>
                    )}
                </>
            )}
        </>
    );
};

export default HistoryTab;

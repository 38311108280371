import React, { useEffect } from "react";
import { useState } from "react";
import { Box } from "@mui/material";
//? components
import { useHistory } from "react-router-dom";
import TabContentWrapper from "./TabContentWrapper/TabContentWrapper";
import Paths from "../../components/constants/Path";
import Styles from "./Tabs.module.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch, useSelector } from "react-redux";
import { setStatus } from "../../redux/Loans/loanSlice";

const Tabs = ({
  tabs,
  state,
  changeHandler,
  submitHandler,
  status,
  isAuditorTab = false,
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [comp, setComp] = useState(null);
  const [showSubmitBtn, setShowSubmitBtn] = useState(null);
  const dispatch = useDispatch();
  let history = useHistory();

  //?setting the component with index here
  const handleClick = (index) => {
    if (isAuditorTab == false) {
      setCurrentTab(index);
    }
    // tabs[index]['opacity'] = false;
    // console.log(tabs[index]['opacity']);
  };
  //? nextTabHandler
  const nextTabHandler = () => {
    if (tabs.length - 1 == currentTab) {
      // alert("No More Tabs for Next.");
      return;
    }

    setCurrentTab((currentTab) => currentTab + 1);
    // console.log(tabs[currentTab+1]['opacity'])
    // tabs[currentTab+1]['opacity'] = false;
    console.log(tabs[currentTab + 1]["opacity"]);
  };

  const testingfn = () => {};

  //? Setting default component in state & resetting the component whenever user will click on tab
  useEffect(() => {
    setComp(tabs[currentTab]);
  }, [currentTab]);

  const editTabhandler = () => {
    if (comp.showEditButton === true) {
      dispatch(setStatus(3));
      return history.push({
        pathname: `/view/${comp.id}/details`,
        state: comp.dealData,
      });
    }
  };
  //? To Show the Left Side Tabs List

  let tabsList =
    tabs &&
    tabs.map((tab, index) => {
      return (
        <div
          style={{ cursor: status?.[index]?.["isChecked"] ? "pointer" : "" }}
          key={index}
          className={currentTab == index ? Styles.tabActive : Styles.tab}
          onClick={() => {
            tab?.isDisabled
              ? console.log("tab is disabled")
              : handleClick(index);
            // status[index]["isChecked"] ? handleClick(index) : console.log("");
          }}
        >
          <p style={{ color: tab?.isDisabled && "rgba(0, 0, 0, 0.6)" }}>
            {tab.tabName}
          </p>

          {status?.[index]?.["isChecked"] ? (
            <CheckCircleIcon
              sx={{
                color: "#41C66E",

                paddingLeft: "8px",
              }}
            ></CheckCircleIcon>
          ) : (
            ""
          )}
        </div>
      );
    });

  return (
    <div className={Styles.tabsWrapper}>
      {/* //? Left Side of Tabs Component */}
      <div className={Styles.left}>{tabsList}</div>
      {/* //? Right Side of Tabs Component */}
      <div className={Styles.right}>
        <TabContentWrapper
          //TabContentWrapper showNextBtn={showNextBtn}
          heading={comp && comp != null && comp.heading ? comp.heading : ""}
          nextTabHandler={nextTabHandler}
          showSubmitBtn={showSubmitBtn}
          editTabhandler={editTabhandler}
          showEditbutton={
            comp && comp != null && comp.showEditButton
              ? comp.showEditButton
              : ""
          }
        >
          {comp != null ? (
            <comp.comp
              state={state}
              changeHandler={changeHandler}
              nextTabHandler={nextTabHandler}
              submitHandler={submitHandler}
            />
          ) : (
            ""
          )}
        </TabContentWrapper>
      </div>
    </div>
  );
};

export default Tabs;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { isAuthenticated } from "../../helper/AuthHelper";

const initialState = {
  checkedPages: [
    { pageName: "selectDeal", isChecked: false },
    { pageName: "uploadDocuments", isChecked: false },
    { pageName: "summary", isChecked: false },
    { pageName: "history", isChecked: false },
  ],
  summary: "",
  loading: null,
  error: null,
};

export const getDPDDocuments = createAsyncThunk(
  "getDPDDocuments",
  async ({ dealId }, { rejectWithValue }) => {
    try {
      const response = await instance.get(
        `/scheduler-service/dailydpd-summary?dealId=${dealId}`
      );
      console.log({ response });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getDPDDocumentsHistory = createAsyncThunk(
  "getDPDDocumentsHistory",
  async ({ dealId }, { rejectWithValue }) => {
    try {
      const response = await instance.get(
        `/scheduler-service/dailydpd-upload-history?dealId=${dealId}`
      );
      console.log({ response });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const uploadDPD = createAsyncThunk(
  "uploadDPD",
  async (values, thunkApi) => {
    let formData = new FormData();
    formData.set("file", values.file);
    formData.set("dealId", values.dealId);
    try {
      const uploadData = await instance.post(
        "/scheduler-service/dailydpd-upload",

        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + isAuthenticated("Token"),
          },
        }
      );
      return {
        ...uploadData,
      };
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

const dailyDPDRequestSlice = createSlice({
  name: "dailyDPDRequestSlice",
  initialState,
  reducers: {
    pageStatusAction: (state, { payload }) => {
      console.log(payload);
      state.checkedPages = state.checkedPages.map((data) => {
        let { pageName, isChecked } = data;

        return payload.pageName === pageName
          ? { ...data, isChecked: true }
          : data;
      });
    },
    clearDailyDPDState: (state) => {
      // eslint-disable-next-line no-unused-expressions
      state.checkedPages = [
        { pageName: "selectDeal", isChecked: false },
        { pageName: "uploadDocuments", isChecked: false },
        { pageName: "summary", isChecked: false },
        { pageName: "history", isChecked: false },
      ],
      state.summary = [];
      state.loading = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDPDDocuments.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDPDDocuments.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.summary = payload;
    });
    builder.addCase(getDPDDocuments.rejected, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.error = "Something went wrong";
    });

    builder.addCase(getDPDDocumentsHistory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDPDDocumentsHistory.fulfilled, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.summary = payload;
    });
    builder.addCase(getDPDDocumentsHistory.rejected, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.error = "Something went wrong";
    });

    builder.addCase(uploadDPD.rejected, (state, { payload }) => {
      console.log({ payload });
      state.loading = false;
      state.error = payload.response.data.message;
    });
  }
})

export const { pageStatusAction, clearDailyDPDState } = dailyDPDRequestSlice.actions;

export const dailyDPDRequestReducer = dailyDPDRequestSlice.reducer;
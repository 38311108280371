import React, { useState, useEffect } from "react";
import {
  Typography,
  Select,
  Grid,
  MenuItem,
  TextField,
  Divider,
  Stack,
  Box,
} from "@mui/material";
import SpocTable from "../../../components/Table/SpocTable";
import WrapperComponent from "../../../components/Wrapper/WrapperComponent";
import ViewDownloadDeleteComp from "../../../components/L2/Phase2DownloadComp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OutlineButton from "../../../components/Buttons/OutlineButton";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDealState,
  getAllDeals,
  setDealStatus,
} from "../../../redux/Deals/dealSlice";
import Paths from "./../../../components/constants/Path";
import {
  showAlertAction,
  showErrorAlertAction,
} from "../../../redux/app/appSlice";
import { postQuery } from "../../../redux/Queries/queriesSlice";
import { fetchQueryList } from "./../../../redux/Queries/queriesSlice";
import { getloanUploadTemplate } from "./../../../redux/Deals/dealSlice";
import LabelWithDownload from "../../../components/LabelWithDownload";
import CTAWrapperHandler from "../../../components/Wrapper/CTAWrapperHandler";
import { statusConstants } from "../../../components/constants/FiltersConstant";
import DetailsData from "../../../components/DetailsData";

const DealDetails = () => {
  const dealState = useSelector((state) => state.deal);
  const [state, setState] = useState("");
  const [queryDetails, setQueryDetails] = useState({
    query: "",
    queryModule: "Deal",
    documentId: "",
  });
  const params = useParams();
  const dispatch = useDispatch();
  let history = useHistory();
  const handleInputChange = (name) => (e) => {
    let value = e.target.value;
    setState(value);
  };

  useEffect(() => {
    console.log({ state });
  }, [state]);

  const Placeholder = ({ children }) => {
    return <div style={{ color: "#AFB9C3" }}>{children}</div>;
  };

  useEffect(() => {
    if (dealState?.loanTypeData?._id !== undefined) {
      dispatch(getloanUploadTemplate(dealState?.loanTypeData?._id));
      return;
    }
  }, [dealState?.loanTypeData]);

  let userData = JSON.parse(localStorage.getItem("UserData"));

  console.log({ dealState });
  const DataComp = ({ title, desc }) => {
    return (
      <div>
        <Typography fontSize="14px" fontWeight="400" color="#808080">
          {title}
        </Typography>
        <Typography fontSize="14px" fontWeight="400">
          {desc}
        </Typography>
      </div>
    );
  };

  const DealData = [
    {
      title: "Type of Transaction",
      desc: dealState?.transactionType,
    },
    {
      title: "Entity",
      desc: dealState?.entity,
    },
    {
      title: "Product / Asset Class",
      desc: dealState?.loanTypeData?.name,
    },
    {
      title: "Vintage in Operations",
      desc: `${dealState?.vintageOperation} years`,
    },
    {
      title: "Rating",
      desc: dealState?.rating,
    },
    {
      title: "AUM",
      desc: `${dealState?.aum} (INR Crs)`,
    },
    {
      title: "Net Worth",
      desc: `${dealState?.netWorth} (INR Crs)`,
    },
    {
      title: "PAT",
      desc: `${dealState?.pat} (INR Crs)`,
    },
    {
      title: "CRAR%",
      desc: `${dealState?.crarPercent}%`,
    },
    {
      title: "GNPA%",
      desc: `${dealState?.gnpaPercent}%`,
    },
    {
      title: "NNPA %",
      desc: `${dealState?.nnpaPercent}%`,
    },
    {
      title: "Requested Deal Size",
      desc: `₹ ${dealState?.requestedDealAmount} Cr.`,
    },
    // {
    //   title: "Bureau Name",
    //   desc: "Highmark",
    // },
  ];

  useEffect(() => {
    setState(dealState.status);
  }, [dealState.status]);

  console.log({ dealState });

  const OriginatorsList = [
    { id: 2, name: "Anupam Pandey" },
    { id: 3, name: "Purvaa Chandurkar" },
    { id: 4, name: "Jhanvi Soni" },
  ];

  //handle CTA functionality
  const CTAClickHandler = (queryDetails) => {
    //handle status update api
    if (dealState.status != statusConstants.APPROVED && dealState.status != statusConstants.CLOSED) {
      if (state != "") {
        dispatch(setDealStatus({ id: params.dealId, status: state })).then(
          (res) => {
            if (res?.payload?.status == 200) {
              dispatch(showAlertAction(res?.payload?.data?.message));
            }

            history.push(Paths.L2DEALLIST);
          }
        );
      }

      //handle empty state selection case
      if (state == "") {
        dispatch(showErrorAlertAction("Please Select Status"));
      }
    }

    //handle query api to post query

    if (queryDetails.query != "" && state != "") {
      dispatch(postQuery(queryDetails)).then((data) => {
        let id = params.dealId;
        dispatch(fetchQueryList({ id }));
        dispatch(showAlertAction(data.payload.data.message));
        history.push("/l2deallist");
      });
    }
  };

  return (
    <>
      <WrapperComponent title="Deal Details">
        <>
          <div
            style={{
              padding: "14px",
            }}
          >
            <Grid container spacing={3}>
              {DealData.map((data) => {
                return (
                  <>
                    {data?.desc != "" &&
                      data?.desc != "undefined%" &&
                      data?.desc != "null%" &&
                      data?.desc != "undefined years" &&
                      data?.desc != "₹ undefined Cr." &&
                      data?.desc != "null years" &&
                      data?.desc != "undefined (INR Crs)" &&
                      data?.desc != "null (INR Crs)" &&
                      data?.desc != "₹ null Cr." &&
                      data?.desc != undefined && (
                        <Grid item xs={3}>
                          <DataComp title={data.title} desc={data.desc} />
                        </Grid>
                      )}
                  </>
                );
              })}
            </Grid>
          </div>
        </>
      </WrapperComponent>

      {dealState?.status === statusConstants.CLOSED && (
        <DetailsData label="Financial Details" />
      )}

      <WrapperComponent title="SPOC Details">
        <>
          <div>
            <SpocTable data={dealState?.contacts}></SpocTable>
          </div>
        </>
      </WrapperComponent>

      {dealState?.originatorIdData && dealState?.originatorIdData !== null && (
        <WrapperComponent title="Originator Details">
          <>
            <div>
              <SpocTable data={[dealState?.originatorIdData]}></SpocTable>
            </div>
          </>
        </WrapperComponent>
      )}

      <WrapperComponent title="Documents">
        <>
          <div
            style={{
              padding: "14px",
            }}
          >
            {/* <Typography
              fontSize="14px"
              color="#808080"
              fontWeight="400"
              paddingBottom="10px"
            >
              Loan Upload Template
            </Typography> */}

            <LabelWithDownload
              label="Loan Upload Template"
              fileId={dealState?.loanUploadTemplate?.fileData?._id}
              fileName={dealState.loanUploadTemplate?.fileData?.filename}
            />

            {/* <ViewDownloadDeleteComp
              fileName={dealState.loanUploadTemplate?.fileData?.fileName || dealState.loanUploadTemplate?.fileData?.filename || ""}
              id={dealState.loanUploadTemplate?.fileData?._id}
              showEye={true}
            ></ViewDownloadDeleteComp> */}
          </div>
        </>
      </WrapperComponent>
      {dealState?.status === statusConstants.CLOSED &&
        dealState?.filterdata?.migratedFile !== undefined &&
        dealState?.filterdata?.migratedFile?.fileName !== "" && (
          <WrapperComponent title="Documents">
            <>
              <div
                style={{
                  padding: "14px",
                }}
              >
                <Typography
                  fontSize="14px"
                  color="#808080"
                  fontWeight="400"
                  paddingBottom="10px"
                >
                  Migragted Deal Data
                </Typography>

                <ViewDownloadDeleteComp
                  fileName={
                    dealState?.filterdata?.migratedFile?.fileName ||
                    dealState?.filterdata?.migratedFile?.filename ||
                    ""
                  }
                  id={dealState?.filterdata?.migratedFile?._id}
                  showEye={true}
                ></ViewDownloadDeleteComp>
              </div>
            </>
          </WrapperComponent>
        )}

      {userData?.roles.length == 1 ? (
        <CTAWrapperHandler title="Case Status">
          <div
            style={{
              padding: "14px",
            }}
          >
            <Typography
              fontSize="14px"
              color="#365069"
              fontWeight="700"
              paddingBottom="10px"
            >
              Select Status
            </Typography>
            <div
              style={{
                justifyContent: "space-between",
                alignItems: "space-between",
                display: "flex",
              }}
            >
              <div
                style={{
                  width: "368px",
                }}
              >
                {dealState?.status === statusConstants.APPROVED ? (
                  <Select
                    IconComponent={ExpandMoreIcon}
                    value={state}
                    onChange={handleInputChange("originatorType")}
                    disabled={dealState.status === statusConstants.APPROVED ? true : false}
                    displayEmpty
                    size={"small"}
                    variant="standard"
                    fullWidth
                    renderValue={
                      state !== ""
                        ? undefined
                        : () => <Placeholder>Select an Option</Placeholder>
                    }
                  >
                    <MenuItem value={statusConstants.APPROVED}>Approved</MenuItem>
                    <MenuItem value={"ON_HOLD"}>On Hold</MenuItem>
                    <MenuItem value={statusConstants.PENDING}>Pending</MenuItem>
                    <MenuItem value={statusConstants.REJECTED}>Rejected</MenuItem>
                  </Select>
                ) : dealState?.status === statusConstants.CLOSED ? (
                  <Select
                    IconComponent={ExpandMoreIcon}
                    value={state}
                    onChange={handleInputChange("originatorType")}
                    disabled={dealState.status === statusConstants.CLOSED ? true : false}
                    displayEmpty
                    size={"small"}
                    variant="standard"
                    fullWidth
                    renderValue={
                      state !== ""
                        ? undefined
                        : () => <Placeholder>Select an Option</Placeholder>
                    }
                  >
                    <MenuItem value={statusConstants.APPROVED}>Approved</MenuItem>
                    <MenuItem value={"ON_HOLD"}>On Hold</MenuItem>
                    <MenuItem value={statusConstants.PENDING}>Pending</MenuItem>
                    <MenuItem value={statusConstants.REJECTED}>Rejected</MenuItem>
                    <MenuItem value={statusConstants.CLOSED}>Closed</MenuItem>
                  </Select>
                ) : (
                  <Select
                    IconComponent={ExpandMoreIcon}
                    value={state}
                    onChange={handleInputChange("originatorType")}
                    displayEmpty
                    size={"small"}
                    variant="standard"
                    fullWidth
                    renderValue={
                      state !== ""
                        ? undefined
                        : () => <Placeholder>Select an Option</Placeholder>
                    }
                  >
                    <MenuItem value={statusConstants.APPROVED}>Approved</MenuItem>
                    <MenuItem value={"ON_HOLD"}>On Hold</MenuItem>
                    <MenuItem value={statusConstants.PENDING}>Pending</MenuItem>
                    <MenuItem value={statusConstants.REJECTED}>Rejected</MenuItem>
                  </Select>
                )}
              </div>
            </div>
          </div>

          <Divider></Divider>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="space-between"
            display="flex"
            marginTop="21px"
          >
            <Box display="flex">
              <Typography fontSize="16px" fontWeight="700" paddingBottom={2}>
                Queries
              </Typography>
            </Box>
          </Stack>

          <div
            style={{
              padding: "14px",
            }}
          >
            <Typography
              fontSize="14px"
              color="#365069"
              fontWeight="700"
              paddingBottom="10px"
            >
              Post a Query
            </Typography>

            <Typography
              fontSize="14px"
              color="#AFB9C3"
              fontWeight="400"
              paddingBottom="10px"
            >
              Add your query
            </Typography>
            <div
              style={{
                justifyContent: "space-between",
                alignItems: "space-between",
                display: "flex",
              }}
            >
              <div
                style={{
                  width: "368px",
                }}
              >
                <TextField
                  variant="standard"
                  fullWidth
                  autoComplete="off"
                  onChange={(e) =>
                    setQueryDetails({
                      ...queryDetails,
                      query: e.target.value,
                      documentId: params.dealId,
                      queryStage: dealState?.filterdata?.currentStage || "",
                    })
                  }
                ></TextField>
              </div>
              <OutlineButton
                text="Update"
                showIcon={false}
                onClickHandler={() =>
                  dealState.status != statusConstants.CLOSED
                    ? CTAClickHandler(queryDetails)
                    : null
                }
              ></OutlineButton>
            </div>
          </div>
        </CTAWrapperHandler>
      ) : (<></>)}

      <div style={{ padding: "10px" }}></div>
    </>
  );
};

export default DealDetails;

import React from "react";
import WrapperComponent from "./Wrapper/WrapperComponent";
import { Grid } from "@mui/material";
import LabelWithDownload from "./LabelWithDownload";
import { useSelector } from "react-redux";
import { useCallback } from "react";

export default function AllDocumentsV2() {
  const dealState = useSelector((state) => state.deal);

  const [expandedKey, setExpandedKey] = React.useState("");

  const poolUploadFinal = dealState?.allDealDocuments;

  const getFileNameFromId = (id) => {
    return poolUploadFinal?.files?.filter((item) => item._id === id)?.[0]
      ?.filename;
  };

  const RenderDocuments = useCallback(
    (section) => {
      return poolUploadFinal?.[section]?.map((file, index) => {
        return (
          <Grid item xs={4} key={file?.key + index}>
            {file?.values
              ?.slice(0, expandedKey === file?.key ? file?.values?.length : 1)
              ?.map((value, idx) => (
                <div style={{ marginTop: "10px" }} key={value}>
                  <LabelWithDownload
                    fileName={getFileNameFromId(value)}
                    fileId={value}
                    label={
                      file?.key +
                      " " +
                      (file?.values?.length > 1 ? idx + 1 : "")
                    }
                  />
                </div>
              ))}
            {file?.values?.length > 1 && (
              <div
                style={{
                  marginTop: "2px",
                  cursor: "pointer",
                  color: "#007bff",
                  fontSize: "14px",
                }}
                onClick={() => {
                  setExpandedKey(expandedKey === file?.key ? "" : file?.key);
                }}
              >
                {expandedKey === file?.key
                  ? `Show ${file?.values?.length - 1} Less`
                  : `Show ${file?.values?.length - 1} More`}
              </div>
            )}
          </Grid>
        );
      });
    },
    [poolUploadFinal, expandedKey]
  );

  return (
    <WrapperComponent title="Related Supporting Documents">
      <>
        <div
          style={{
            paddingTop: "14px",
          }}
        >
          <Grid container spacing={4}>
            {[
              "supportingDocuments",
              "lossEstimateReports",
              "termsheets",
              "legalDocuments",
              "otherDocuments",
            ].map((section) => RenderDocuments(section))}
          </Grid>
        </div>
      </>
    </WrapperComponent>
  );
}

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Stack, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Pagination from "../Pagination/Pagination";
import _ from "lodash";
import moment from "moment";
import { actionTypo, actionsIcon, norecordFoundStyle, originatorNameStyle, statusTopBox } from "./TableCSS";

const KYCLinkDealListTable = ({ data, viewActionClickHandler, header, statusList, page, totalRecords,
  nextPageHandler,
  prevPageHandler, }) => {

  let sortedData = [...data];
  sortedData.sort((a, b) => {
    if (a.PendingAction > b.PendingAction) {
      return -1;
    }
    if (a.PendingAction < b.PendingAction) {
      return 1;
    }
    return 0;
  })

  let filterData = data.filter((item) => item.PendingAction === "Pending");
  let newData = [...filterData, ...sortedData];
  let finalData = _.uniqBy(newData, (e) => e.id);

  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    textAlign: "center",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "700",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#EBEEF0",
      color: "#5E7387",
      fontSize: "12px",
      fontWeight: "500",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "center",
    },
  }));

  const StyledNoBorderTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: "0px solid #EBEEF0",
    textAlign: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column"
  }));


  const StatusContainer = ({ statusType }) => {
    // const dealState = useSelector((state) => state.deal);
    const foundStatus = statusList.find(obj => {
      return obj.status === statusType
    })
    const Textcolor =
      statusType === "APPROVED" ||
        statusType === "Created" ||
        statusType === "COMPLETED"
        ? "#277742"
        : statusType === "REJECTED"
          ? "#8D3431"
          : statusType === "CLOSED"
            ? "#2C4584"
            : "#996E37";

    const boxBackgroudColor =
      statusType === "APPROVED" ||
        statusType === "Created" ||
        statusType === "COMPLETED"
        ? "#E3F6E9"
        : statusType === "REJECTED"
          ? "#FCE6E5"
          : statusType === "CLOSED"
            ? "#E4EAFA"
            : "#FFF4E7";

    return (
      <Box
        sx={statusTopBox}
        style={{
          color: Textcolor,
          backgroundColor: boxBackgroudColor,
        }}
      >
        {statusType &&
          statusType
            .toLowerCase()
            .split(" ")
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(" ")}
      </Box>
    );
  };

  const Actions = ({ label = "View", Icon, item }) => {
    // label = "View"
    return (
      <div onClick={() => viewActionClickHandler(item)} style={{ cursor: "pointer" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
        >
          <Icon sx={actionsIcon}></Icon>

          <Typography sx={actionTypo}>
            {label}
          </Typography>
        </Stack>
      </div>
    );
  };

  return (
    <div className="App">
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {header.map((data) => {
                return <StyledTableCell>{data}</StyledTableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {finalData.map((item) => {
              return (
                <TableRow key={item.id}>
                  <StyledTableCell1 component="th" scope="row">
                    {item?.name}
                    <br />
                    <Typography
                      sx={originatorNameStyle}
                    >
                      {item?.originatorName}
                    </Typography>
                  </StyledTableCell1>

                  <StyledTableCell component="th" scope="row">
                    {item?.loanType}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {item?.requestedDealAmount}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {item?.entity === undefined && "NA"}
                    {item?.entity === "" && "NA"}
                    {item?.entity}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {moment(item?.createdAt).format("D MMM YYYY")}
                  </StyledTableCell>

                  <StyledTableCell component="th" scope="row">
                    <StatusContainer
                      statusType={item?.kycDriveLinkStatus}
                    ></StatusContainer>
                  </StyledTableCell>

                  <TableCell>
                    <StyledNoBorderTableCell>
                      <Actions Icon={VisibilityIcon} item={item}></Actions>
                    </StyledNoBorderTableCell>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {data.length === 0 && (
          <Typography sx={norecordFoundStyle}>
            No Records Found!!!
          </Typography>
        )}
      </TableContainer>
      {
        data && data.length === 0 ? null : (
          <Pagination
            Page={page}
            TotalNoNumber={totalRecords}
            nextPageHandler={page === totalRecords ? null : nextPageHandler}
            prevPageHandler={prevPageHandler}
          />
        )
      }
    </div>
  );
};

export default KYCLinkDealListTable;
